const ConfigurationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
            <mask id="IconifyId18bee8876e935a8440">
                <g fill="none" strokeLinejoin="round" strokeWidth="4">
                    <path
                        fill="#fff"
                        stroke="#fff"
                        d="M18.284 43.171a19.995 19.995 0 0 1-8.696-5.304a6 6 0 0 0-5.182-9.838A20.09 20.09 0 0 1 4 24c0-2.09.32-4.106.916-6H5a6 6 0 0 0 5.385-8.65a19.968 19.968 0 0 1 8.267-4.627A6 6 0 0 0 24 8a6 6 0 0 0 5.348-3.277a19.968 19.968 0 0 1 8.267 4.627A6 6 0 0 0 43.084 18A19.99 19.99 0 0 1 44 24c0 1.38-.14 2.728-.406 4.03a6 6 0 0 0-5.182 9.838a19.995 19.995 0 0 1-8.696 5.303a6.003 6.003 0 0 0-11.432 0Z"
                    />
                    <path fill="#000" stroke="#000" d="M24 31a7 7 0 1 0 0-14a7 7 0 0 0 0 14Z" />
                </g>
            </mask>
            <path fill="#ffffff" d="M0 0h48v48H0z" mask="url(#IconifyId18bee8876e935a8440)" />
        </svg>
    );
};

export default ConfigurationIcon;
