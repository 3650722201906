const AlineacionesManagersIcon = ({ iconWidth, iconHeight, color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 25}
            height={iconHeight ?? 25}
            viewBox="0 0 2048 2048"
        >
            <path
                fill={color ?? '#ffffff'}
                d="M1536 640h-512V512h512v128zm-512 384h512v128h-512v-128zm131 896l128 128H256V0h1536v1283l-128 128V128H384v1792h771zM941 429L704 666L531 493l90-90l83 83l147-147l90 90zm0 512l-237 237l-173-173l90-90l83 83l147-147l90 90zm-237 569l147-147l90 90l-237 237l-173-173l90-90l83 83zm1325-57l-557 558l-269-270l90-90l179 178l467-466l90 90z"
            />
        </svg>
    );
};

export default AlineacionesManagersIcon;
