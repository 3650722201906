const NoInscritoIcon = ({ width, height }) => {
    const defaultWidth = 17;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? defaultWidth}
            height={height ?? defaultWidth}
            viewBox="0 0 64 64"
        >
            <path
                fill="#d0d1d2"
                d="M52.01 8.303c-1.393-1.285-2.685-2.671-4-4.02H11.97C8.676 4.283 6 6.707 6 9.701v48.836c0 2.995 2.676 5.419 5.97 5.419h38.08c3.302 0 5.973-2.424 5.973-5.419v-47.1c-1.393-.977-2.756-1.99-4.01-3.141"
            />
            <path
                fill="#e7e6e6"
                d="M45.41.465H11.972c-3.294 0-5.97 2.425-5.97 5.418v48.841c0 2.99 2.676 5.42 5.97 5.42h38.08c3.302 0 5.973-2.43 5.973-5.42v-42.45C52.171 8.631 48.61 4.727 45.415.464"
            />
            <path
                fill="#35494d"
                d="M13.529 13.59h33.872c2.278 0 2.278-3.203 0-3.203H13.529c-2.274 0-2.274 3.203 0 3.203m0 6.5h33.872c2.278 0 2.278-3.201 0-3.201H13.529c-2.274 0-2.274 3.201 0 3.201m0 6.648h33.872c2.278 0 2.278-3.203 0-3.203H13.529c-2.274 0-2.274 3.203 0 3.203m0 6.502h33.872c2.278 0 2.278-3.203 0-3.203H13.529c-2.274 0-2.274 3.203 0 3.203m0 12.548h15.59c2.274 0 2.274-3.204 0-3.204h-15.59c-2.274 0-2.274 3.204 0 3.204"
            />
            <path
                fill="#d0d1d2"
                d="M56.647 13.316c-3.212-4.048-6.42-8.091-9.634-12.139c-.288-.368-1.051-.368-1.343 0c-3.219 4.048-6.42 8.091-9.634 12.139c-.297.372.082.854.673.854h19.266c.595 0 .978-.482.672-.854"
            />
            <path
                fill="#e7e6e6"
                d="M57.56 12.3C54.206 8.28 50.859 4.26 47.5.23c-.302-.365-1.096-.365-1.401 0c-3.362 4.02-6.705 8.05-10.06 12.07c-.311.37.087.849.703.849h20.11c.619 0 1.018-.479.699-.849"
            />
        </svg>
    );
};

export default NoInscritoIcon;
