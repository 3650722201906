import CustomImageComponent from '../custom/custom-image';

const QuinielaAnaliticaIcon = () => {
    return (
        <CustomImageComponent
            src={`${process.env.NEXT_PUBLIC_IMAGES}/logos/quiniela.png`}
            alt="Quiniela Analítica"
            width={30}
            height={30}
        />
    );
};

export default QuinielaAnaliticaIcon;
