const CheckIcon = ({ width, height }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 20} height={height ?? 20} viewBox="0 0 48 48">
            <path
                fill="#059669"
                fillRule="evenodd"
                d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Zm10.742-26.33a1 1 0 1 0-1.483-1.34L21.28 29.567l-6.59-6.291a1 1 0 0 0-1.382 1.446l7.334 7l.743.71l.689-.762l12.667-14Z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default CheckIcon;
