const KitDigitalIcon = ({ iconWidth, iconHeight, color }) => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={iconWidth ?? 30}
                height={iconHeight ?? 30}
                viewBox="0 0 24 24">
                <path
                    fill={color ?? '#6025E1'}
                    fillRule="evenodd"
                    d="M4.353 4.7v14.476L9.177 22l10.47-5.059V6.976L9.665 2zm.182.07L9.06 7.907v13.882l.94-4.865l4.941-2.206V9.306L4.535 4.765z"
                    clipRule="evenodd" />
            </svg>
        </div>
    );
}

export default KitDigitalIcon;
