import { Fragment, useContext, useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { ToastContainer } from 'react-toastify';

import axiosApiInstance from '@/api/shared/axios-instance';
import { REDUCERS } from '@/constants/reducers';
import { Rutas } from '@/constants/rutas';
import { SITE_USER_COOKIE } from '@/constants/site';
import { SUBSCRIPTION_TYPE } from '@/constants/subscription-type';
import { Context } from '@/context';
import {
    addGoogleTagManagerAndAnalytics,
    addHbAgencyScript,
    addVideooTvScript,
    adsHelper,
} from '@/helpers/ads-helper';
import { getFromLocalStorage, removeFromLocalStorage } from '@/helpers/cookies';
import URL_FACTORY from '@/helpers/url-helper';

import PwaBanner from '../add-to-home-screen/pwa-banner';
import HbAdComponent, { HbAdType } from '../ads-components/hb-agency/hb-ad';
import PlacementBottomComponent from '../ads-components/placements/placement-bottom';
import PlacementTopComponent from '../ads-components/placements/placement-top';
import PlacementTopDesktopComponent from '../ads-components/placements/placement-top-desktop';
import MasterPlayerModalComponent from '../master-player-modal';
import FantasyHistoricModalComponent from '../master-player-modal/fantasy-historic-modal';

import ActividadBanner from './actividad-banner';
import DesktopNavigationComponent from './desktop-navigation';
import FooterSection from './footer';
import SecondaryNavigationComponent from './jugadores-navigation-component';
import LeftDrawerComponent from './left-drawer-component';
import MobileNavigationComponent from './mobile-navigation';
import RightDrawerComponent from './right-drawer-component';
import TeamLogoComponent from './team-logo';
import { theme } from './theme';
import TwitchLive from './twitch-live';
import LazyLoadWrapper from './wrappers/lazy-load-wrapper';

const Layout = (props) => {
    const { state, dispatch } = useContext(Context);
    const { app, user } = state;
    const isXXL = useMediaQuery('(min-width: 1400px)', { noSsr: true });
    const isMobile = useMediaQuery('(max-width: 576px)', { noSsr: true });
    const [rightDrawer, setRightDrawer] = useState(false);
    const [leftDrawer, setLeftDrawer] = useState(false);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);
    const router = useRouter();
    const [isMobileScreen, setIsMobileScreen] = useState(null);
    const [isXXLMobileScreen, setIsXXLMobileScreen] = useState(null);

    useEffect(() => {
        setIsXXLMobileScreen(isXXL);
    }, [isXXL]);

    useEffect(() => {
        dispatch({ type: REDUCERS.UPDATE_USER, payload: { currentUrl: router?.asPath } });
    }, [router?.asPath]);

    useEffect(() => {
        setIsMobileScreen(isMobile);
    }, [isMobile, isXXL]);

    const handleCloseLeftDrawer = () => {
        setLeftDrawer(false);
    };

    const handleCloseRightDrawer = () => {
        setRightDrawer(false);
    };

    const handleHamburguerMenu = () => {
        setRightDrawer(false);
        setLeftDrawer(!leftDrawer);
    };

    const handlePartidosMenu = () => {
        setRightDrawer(!rightDrawer);
        setLeftDrawer(false);
    };

    const getFixturesDrawer = () => {
        axiosApiInstance
            .get(URL_FACTORY.GET_URL_RecentFixtures)
            .then((response) => {
                dispatch({
                    type: REDUCERS.UPDATE_CONFIGURATION,
                    payload: response.data?.configuration,
                });

                dispatch({
                    type: REDUCERS.UPDATE_FIXTURES_ROUND,
                    payload: {
                        rounds: response.data.rounds,
                        activeRoundIndex: response.data.activeIndex,
                    },
                });

                const headerValue = response.headers?.['af-expired'];

                if (headerValue) {
                    removeFromLocalStorage(SITE_USER_COOKIE);
                    window.location.href = Rutas.INICIO;
                }
            })
            .catch(() => {});
    };

    const getNavigationMenu = () => {
        if (isXXLMobileScreen) {
            return (
                <DesktopNavigationComponent
                    logged={user.logged}
                    isAdmin={user.isAdmin}
                    handlePartidosMenu={handlePartidosMenu}
                    handleHamburguerMenu={handleHamburguerMenu}
                />
            );
        } else {
            return (
                <MobileNavigationComponent
                    logged={user.logged}
                    isAdmin={user.isAdmin}
                    handleHamburguerMenu={handleHamburguerMenu}
                    handlePartidosMenu={handlePartidosMenu}
                />
            );
        }
    };

    const getLeftDrawer = () => {
        return <LeftDrawerComponent anchor="left" open={leftDrawer} handleClose={handleCloseLeftDrawer} />;
    };

    const getRightDrawer = () => {
        return (
            <RightDrawerComponent
                anchor="right"
                open={rightDrawer}
                handleClose={handleCloseRightDrawer}
                handlePartidosMenu={handlePartidosMenu}
                logged={app.logged}
            />
        );
    };

    useEffect(() => {
        getFixturesDrawer();
    }, []);

    useEffect(() => {
        dispatch({ type: REDUCERS.GET_DATA_FROM_COOKIES });
    }, [user?.subscriptionType]);

    const addAdScripts = () => {
        const isProduction = process.env.NEXT_PUBLIC_ENV_VARIABLE !== 'development';

        if (!isProduction) {
            return false;
        }

        const dataFromCookies = getFromLocalStorage(SITE_USER_COOKIE);
        const data = JSON.parse(dataFromCookies);

        if (data && data.subscriptionType !== SUBSCRIPTION_TYPE.FREE) {
            return false;
        }

        addVideooTvScript();
        addHbAgencyScript();

        return true;
    };

    useEffect(() => {
        // Función para verificar el consentimiento actual
        const checkConsent = () => {
            if (window.__tcfapi) {
                window.__tcfapi('getTCData', 2, (tcData, success) => {
                    if (
                        success &&
                        tcData.purpose?.consents?.[1] === true // Verificar que el consentimiento ya está dado
                    ) {
                        setCookiesAccepted(true);
                    }
                });
            }
        };

        // Verificación inicial al cargar la página
        checkConsent();

        // Listener para cambios en el consentimiento
        const handleConsentChange = (tcData, success) => {
            if (
                success &&
                tcData.eventStatus === 'useractioncomplete' &&
                tcData.purpose?.consents?.[1] === true
            ) {
                setCookiesAccepted(true);
            }
        };

        // Escuchar cambios en el consentimiento con el evento addEventListener de TCF
        if (window.__tcfapi) {
            window.__tcfapi('addEventListener', 2, handleConsentChange);
        }

        // Limpieza del listener al desmontar el componente
        return () => {
            if (window.__tcfapi) {
                window.__tcfapi('removeEventListener', 2, handleConsentChange);
            }
        };
    }, []);

    useEffect(() => {
        if (cookiesAccepted) {
            const scriptSetup = addAdScripts();
            addGoogleTagManagerAndAnalytics();

            if (scriptSetup) {
                dispatch({ type: REDUCERS.UPDATE_APP, payload: { cookiesAccepted: true } });
            }
            // else {
            //     const script = document.getElementById('hbagency-script');
            //     if (script) {
            //         script.remove();
            //     }
            // }
        }
    }, [cookiesAccepted]);

    useEffect(() => {
        if (cookiesAccepted) {
            adsHelper.addAdsense();
        }
    }, [cookiesAccepted, router.asPath]);

    const getCurrentUrl = (url) => {
        return url?.split('/')?.[1] ?? '';
    };

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                {getNavigationMenu()}
                {getLeftDrawer()}
                {getRightDrawer()}
                <section
                    className={`${
                        user?.subscriptionType !== SUBSCRIPTION_TYPE.FREE ||
                        process.env.NEXT_PUBLIC_ENV_VARIABLE === 'development'
                            ? 'analitica-premium'
                            : 'analitica-free'
                    } main-content url-${getCurrentUrl(router.asPath)}`}
                >
                    <TeamLogoComponent />
                    <SecondaryNavigationComponent />
                    {isMobileScreen === true && <PlacementTopComponent />}
                    {isMobileScreen === false && <PlacementTopDesktopComponent />}

                    <div className="container">
                        <TwitchLive />
                        <ActividadBanner />
                    </div>
                    <main className="main-website">{props.children}</main>
                    <PlacementBottomComponent />
                    <LazyLoadWrapper>
                        <FooterSection />
                    </LazyLoadWrapper>
                </section>

                <ToastContainer />

                <PwaBanner />

                <MasterPlayerModalComponent />
                <FantasyHistoricModalComponent />
                <div id="ads-scripts"></div>

                <HbAdComponent type={HbAdType.MAGIC_LEFT} />
                <HbAdComponent type={HbAdType.STICKY_BOTTOM} />
            </ThemeProvider>
        </Fragment>
    );
};

export default Layout;
