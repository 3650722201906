import { LEAGUES, LEAGUES_NAMES, LEAGUES_SEO_NAME } from '@/constants/leagues';

export const seasons = [
    { label: '2024 (Actual)', value: 2024 },
    { label: '2023', value: 2023 },
    { label: '2022', value: 2022 },
    { label: '2021', value: 2021 },
    { label: '2020', value: 2020 },
    { label: '2019', value: 2019 },
    { label: '2018', value: 2018 },
    { label: '2017', value: 2017 },
    { label: '2016', value: 2016 },
];

export const onlyCurrentseason = [{ label: '2023 (Actual)', value: 2023 }];

export const ligas = [
    { label: 'La Liga', value: 140, seasons: seasons },
    { label: 'Champions League', value: 2, seasons: seasons },
    { label: 'UEFA Europa League', value: 3, seasons: seasons },
    { label: 'Supercopa de España', value: 556, seasons: onlyCurrentseason },
    { label: 'Mundial de Clubes', value: 15, seasons: onlyCurrentseason },
    { label: 'UEFA Europa Conference League', value: 848, seasons: onlyCurrentseason },
];

export const clasificacionLigas = [
    { label: 'La Liga', value: 140, seasons: seasons },
    { label: 'Segunda división', value: 141, seasons: seasons },
    { label: 'Champions League', value: 2, seasons: seasons },
    { label: 'UEFA Europa League', value: 3, seasons: seasons },
    { label: 'UEFA Europa Conference League', value: 848, seasons: onlyCurrentseason },
    { label: 'Premier League', value: 39, seasons: seasons },
];

export const availableLeagues = [
    { label: 'La Liga', value: LEAGUES.LA_LIGA },
    { label: 'Champions League', value: LEAGUES.CHAMPIONS_LEAGUE },
    { label: 'Copa del Rey', value: LEAGUES.COPA_DEL_REY },
    { label: 'Europa League', value: LEAGUES.EUROPA_LEAGUE },
    // { label: 'Premier League', value: LEAGUES.PREMIER_LEAGUE },
    // { label: 'Serie A', value: LEAGUES.SERIE_A },
    // { label: 'Bundesliga', value: LEAGUES.BUNDESLIGA },
    // { label: 'Ligue 1', value: LEAGUES.LIGUE_1 },
];

export const allPositions = [
    { label: 'Todos', value: 0 },
    { label: 'Porteros', value: 1 },
    { label: 'Defensas', value: 2 },
    { label: 'Medios', value: 3 },
    { label: 'Delanteros', value: 4 },
    { label: 'Entrenadores', value: 5 },
];

export const positions = [
    { label: 'PO', value: 1 },
    { label: 'DF', value: 2 },
    { label: 'MC', value: 3 },
    { label: 'DL', value: 4 },
    { label: 'ET', value: 5 },
];

export const fantasyPositions = [
    { label: 'Todas', value: 0 },
    { label: 'Porteros', value: 1 },
    { label: 'Defensas', value: 2 },
    { label: 'Medios', value: 3 },
    { label: 'Delanteros', value: 4 },
];

export const fantasySeasons = [
    { label: '2024-2025 (Actual)', value: 2024 },
    { label: '2023-2024', value: 2023 },
    { label: '2022-2023', value: 2022 },
    { label: '2021-2022', value: 2021 },
    { label: '2020-2021', value: 2020 },
];

export const marketOptions = [
    { value: 'last_1', label: 'Último Mercado', color: '#00B8D9', isFixed: true, last: 1 },
    { value: 'last_2', label: 'Últimos 2 mercados', color: '#00B8D9', isFixed: true, last: 2 },
    { value: 'last_3', label: 'Últimos 3 mercados', color: '#00B8D9', isFixed: true, last: 3 },
    { value: 'last_5', label: 'Últimos 5 mercados', color: '#00B8D9', isFixed: true, last: 5 },
    { value: 'last_10', label: 'Últimos 10 mercados', color: '#00B8D9', isFixed: true, last: 10 },
    { value: 'last_14', label: 'Últimos 14 mercados', color: '#00B8D9', isFixed: true, last: 14 },
    { value: 'last_30', label: 'Últimos 30 mercados', color: '#00B8D9', isFixed: true, last: 30 },
];

export const getSeoName = (league) => {
    switch (league) {
        case LEAGUES_SEO_NAME.LA_LIGA:
            return 'La Liga';
        case LEAGUES_SEO_NAME.CHAMPIONS_LEAGUE:
            return 'Champions League';
        case LEAGUES_SEO_NAME.EUROPA_LEAGUE:
            return 'Europa League';
        case LEAGUES_SEO_NAME.COPA_DEL_REY:
            return 'Copa del Rey';
        case LEAGUES_SEO_NAME.UEFA_EUROPA_CONFERENCE_LEAGUE:
            return 'Conference League';
        case LEAGUES_SEO_NAME.SPAIN_SUPER_CUP:
            return 'Supercopa de España';
        case LEAGUES_SEO_NAME.AMISTOSOS_CLUBES:
            return 'Amistosos de Clubes';
        case LEAGUES_SEO_NAME.PREMIER_LEAGUE:
            return 'Premier League';
        case LEAGUES_SEO_NAME.SEGUNDA_DIVISION:
            return 'Segunda división';
        default:
            return '';
    }
};

export const getSeoUrl = (league) => {
    switch (league) {
        case LEAGUES.LA_LIGA:
            return LEAGUES_SEO_NAME.LA_LIGA;
        case LEAGUES.CHAMPIONS_LEAGUE:
            return LEAGUES_SEO_NAME.CHAMPIONS_LEAGUE;
        case LEAGUES.EUROPA_LEAGUE:
            return LEAGUES_SEO_NAME.EUROPA_LEAGUE;
        case LEAGUES.COPA_DEL_REY:
            return LEAGUES_SEO_NAME.COPA_DEL_REY;
        case LEAGUES.UEFA_EUROPA_CONFERENCE_LEAGUE:
            return LEAGUES_SEO_NAME.UEFA_EUROPA_CONFERENCE_LEAGUE;
        case LEAGUES.SPAIN_SUPER_CUP:
            return LEAGUES_SEO_NAME.SPAIN_SUPER_CUP;
        case LEAGUES.AMISTOSOS_CLUBES:
            return LEAGUES_SEO_NAME.AMISTOSOS_CLUBES;
        case LEAGUES.PREMIER_LEAGUE:
            return LEAGUES_SEO_NAME.PREMIER_LEAGUE;
        case LEAGUES.SEGUNDA_DIVISION:
            return LEAGUES_SEO_NAME.SEGUNDA_DIVISION;
        default:
            return '';
    }
};

export const getCompetitionId = (name) => {
    switch (name) {
        case LEAGUES_SEO_NAME.LA_LIGA:
            return LEAGUES.LA_LIGA;
        case LEAGUES_SEO_NAME.CHAMPIONS_LEAGUE:
            return LEAGUES.CHAMPIONS_LEAGUE;
        case LEAGUES_SEO_NAME.EUROPA_LEAGUE:
            return LEAGUES.EUROPA_LEAGUE;
        case LEAGUES_SEO_NAME.UEFA_EUROPA_CONFERENCE_LEAGUE:
            return LEAGUES.UEFA_EUROPA_CONFERENCE_LEAGUE;
        case LEAGUES_SEO_NAME.SPAIN_SUPER_CUP:
            return LEAGUES.SPAIN_SUPER_CUP;
        case LEAGUES_SEO_NAME.COPA_DEL_REY:
            return LEAGUES.COPA_DEL_REY;
        case LEAGUES_SEO_NAME.AMISTOSOS_CLUBES:
            return LEAGUES.AMISTOSOS_CLUBES;
        case LEAGUES_SEO_NAME.PREMIER_LEAGUE:
            return LEAGUES.PREMIER_LEAGUE;
        case LEAGUES_SEO_NAME.SEGUNDA_DIVISION:
            return LEAGUES.SEGUNDA_DIVISION;
        default:
            return '';
    }
};

export const getCompetitionName = (leagueId) => {
    switch (leagueId) {
        case LEAGUES.LA_LIGA:
            return LEAGUES_NAMES.LA_LIGA;
        case LEAGUES.COPA_DEL_REY:
            return LEAGUES_NAMES.COPA_DEL_REY;
        case LEAGUES.CHAMPIONS_LEAGUE:
            return LEAGUES_NAMES.CHAMPIONS_LEAGUE;
        case LEAGUES.EUROPE_SUPER_CUP:
            return LEAGUES_NAMES.EUROPE_SUPER_CUP;
        case LEAGUES.SPAIN_SUPER_CUP:
            return LEAGUES_NAMES.SPAIN_SUPER_CUP;
        case LEAGUES.FIFA_WORLD_CLUB:
            return LEAGUES_NAMES.FIFA_WORLD_CLUB;
        case LEAGUES.UEFA_EUROPA_CONFERENCE_LEAGUE:
            return LEAGUES_NAMES.UEFA_EUROPA_CONFERENCE_LEAGUE;
        case LEAGUES.EUROPA_LEAGUE:
            return LEAGUES_NAMES.EUROPA_LEAGUE;
        case LEAGUES.FIFA_FRIENDLIES:
            return LEAGUES_NAMES.FIFA_FRIENDLIES;
        case LEAGUES.EURO_CLASIFICACION:
            return LEAGUES_NAMES.EURO_CLASIFICACION;
        case LEAGUES.PREMIER_LEAGUE:
            return LEAGUES_NAMES.PREMIER_LEAGUE;
        case LEAGUES.EUROCOPA:
            return LEAGUES_NAMES.EUROCOPA;
        case LEAGUES.COPA_AMERICA:
            return LEAGUES_NAMES.COPA_AMERICA;
        case LEAGUES.AMISTOSOS_CLUBES:
            return LEAGUES_NAMES.AMISTOSOS_CLUBES;
        case LEAGUES.SEGUNDA_DIVISION:
            return LEAGUES_NAMES.SEGUNDA_DIVISION;
        default:
            return '';
    }
};

export const getCompetitionImage = (leagueId, width) => {
    return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/${leagueId}.png?width=${width ?? 25}`;
};

export const getCompetitionColor = (leagueId, theme) => {
    switch (leagueId) {
        case LEAGUES.LA_LIGA:
            return theme.palette.competitions.laLiga;
        case LEAGUES.FIFA_FRIENDLIES:
            return theme.palette.competitions.fifaFriendlies;
        case LEAGUES.CHAMPIONS_LEAGUE:
            return theme.palette.competitions.championsLeague;
        case LEAGUES.SPAIN_SUPER_CUP:
            return theme.palette.competitions.supercopaEspaña;
        case LEAGUES.COPA_DEL_REY:
            return theme.palette.competitions.copaRey;
        case LEAGUES.EUROPA_LEAGUE:
            return theme.palette.competitions.europaLeague;
        case LEAGUES.EURO_CLASIFICACION:
            return theme.palette.competitions.euroClasificacion;
        default:
            return theme.palette.competitions.defaultColor;
    }
};

export const getTeamNameNormalized = (team) => {
    return team.replace(' ', '-').toLowerCase();
};

export const traduccionGrupos = {
    'Group A': 'Grupo A',
    'Group B': 'Grupo B',
    'Group C': 'Grupo C',
    'Group D': 'Grupo D',
    'Group E': 'Grupo E',
    'Group F': 'Grupo F',
    'Group G': 'Grupo G',
    'Group H': 'Grupo H',
};
