import { useEffect, useState } from 'react';

import { AppBar, Tab, Tabs, Typography } from '@mui/material';

import FantasyLaLigaEstadisticasIcon from '../shared/icons/fantasy-la-liga-estadisticas-icon';
import MercadoIcon from '../shared/icons/mercado-icon';
import ArieteIcon from '../shared/icons/recommendation-icons/ariete-icon';

import MercadoTabComponent from './tabs/mercado-tab';
import NextsFixturesComponent from './tabs/next-fixtures';
import PartidosTabComponent from './tabs/partidos-tab';
import PuntuacionTabComponent from './tabs/puntuacion-tab';

const MasterPlayerTabsComponent = ({ selectedIndex, player }) => {
    const [currentTab, setCurrentTab] = useState(selectedIndex ?? 1);
    const [currentSubTab, setCurrentSubTab] = useState(0);

    const handleChange = (tabIndex) => {
        setCurrentTab(tabIndex);
    };

    const handleSubTabChange = (event, newValue) => {
        setCurrentSubTab(newValue);
    };

    const tabs = [
        {
            index: 1,
            text: 'Partidos',
            icon: <ArieteIcon width={20} height={20} />,
            subTabs: [
                {
                    index: 0,
                    text: 'Últimos partidos',
                    component: <PartidosTabComponent player={player} />,
                },
                {
                    index: 1,
                    text: 'Próximos partidos',
                    component: <NextsFixturesComponent player={player} />,
                },
            ],
        },
        {
            index: 2,
            text: 'Mercado',
            icon: <MercadoIcon />,
            component: <MercadoTabComponent player={player} />,
        },
        {
            index: 3,
            text: 'Puntuación',
            icon: <FantasyLaLigaEstadisticasIcon iconWidth={25} iconHeight={25} />,
            component: <PuntuacionTabComponent player={player} />,
        },
    ];

    useEffect(() => {
        if (player?.fixtures?.lastFixtures?.length > 0) {
            setCurrentSubTab(0);
        } else {
            setCurrentSubTab(1);
        }
    }, [player]);

    return (
        <section className="fixture-rounds-tabs" style={{ marginBottom: '0', paddingBottom: '0' }}>
            <div className="af-tab-list af-light-tab-list">
                {tabs.map((tab) => (
                    <div
                        key={tab.index}
                        className={`af-tab ${currentTab === tab.index && 'af-tab-selected'}`}
                        onClick={() => handleChange(tab.index)}
                    >
                        <Typography variant="h6" className="af-tab-text">
                            {tab.text}
                        </Typography>
                        {tab.icon}
                    </div>
                ))}
            </div>

            <div className="tab-content">
                {currentTab === 1 && (
                    <div>
                        <AppBar
                            position="static"
                            sx={{
                                background: '#f5f7f9',
                                color: '#000',
                                boxShadow: 'none',
                                border: '1px solid rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Tabs
                                value={currentSubTab}
                                onChange={handleSubTabChange}
                                indicatorColor="primary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="sub tabs example"
                            >
                                {tabs[0].subTabs.map((subTab) => (
                                    <Tab key={subTab.index} label={subTab.text} />
                                ))}
                            </Tabs>
                        </AppBar>

                        <div style={{ padding: '16px' }}>
                            {currentSubTab === 0 && tabs[0].subTabs[0].component}
                            {currentSubTab === 1 && tabs[0].subTabs[1].component}
                        </div>
                    </div>
                )}

                {currentTab === 2 && <div>{tabs[1].component}</div>}

                {currentTab === 3 && <div>{tabs[2].component}</div>}
            </div>
        </section>
    );
};

export default MasterPlayerTabsComponent;
