import React, { useContext, useEffect, useState } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { useRouter } from 'next/router';


import FullCalendarIcon from '@/components/shared/icons/full-calendar';
import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';
import { esFechaMayorOIgualActual } from '@/helpers/datetime-helper';
import { isMatchInProgress } from '@/helpers/fixtures-helper';

import FixturesNewCardComponent from '../fixture-new-card-component';

function FixturesCard({ maxHeight = '875px', handleClose }) {
    const router = useRouter();
    const [value, setValue] = React.useState('1');
    const [inProgressFixtures, setInProgressFixtures] = useState(null);
    const [pastFixtures, setPastFixtures] = useState(null);
    const [upcomingFixtures, setUpcomingFixtures] = useState(null);

    const { state } = useContext(Context);
    const { fixtures } = state;

    const handleClickCalendar = () => {
        if (handleClose) {
            handleClose();
        }
        router.push(Rutas.CALENDARIO_COMPETICIONES);
    };

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const handleFixture = (fixture) => {
        if (handleClose) {
            handleClose();
        }

        const link = `${Rutas.PARTIDO}/${fixture.fixtureId}/alineaciones-probables`;
        router.push(link);
    };

    useEffect(() => {
        if (fixtures?.rounds?.length > 0) {
            let allFixtures = [];

            fixtures.rounds.forEach((round) => {
                allFixtures = allFixtures.concat(round.fixtures);
            });

            const pastFixturesList = allFixtures.filter((x) => esFechaMayorOIgualActual(x.date));
            const upcomingFixturesList = allFixtures.filter((x) => !esFechaMayorOIgualActual(x.date));
            const inProgressFixturesList = allFixtures.filter((fixture) =>
                isMatchInProgress(fixture.fixtureStatus)
            );

            const pastFixturesListSorted = pastFixturesList.sort(
                (a, b) => new Date(b.date) - new Date(a.date)
            );

            const upcomingListSorted = upcomingFixturesList.sort(
                (a, b) => new Date(a.date) - new Date(b.date)
            );

            setPastFixtures(pastFixturesListSorted);
            setUpcomingFixtures(upcomingListSorted);
            setInProgressFixtures(inProgressFixturesList);

            if (inProgressFixturesList?.length > 0) {
                setValue('3');
            } else {
                setValue('1');
            }
        }
    }, [fixtures?.rounds]);

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        sx={{ display: 'flex', width: '100%', position: 'sticky' }}
                    >
                        <Tab
                            label="Próximos partidos"
                            value="1"
                            sx={{
                                flexGrow: 1,
                                flexBasis: 0,
                            }}
                        />
                        <Tab
                            label="Últimos partidos"
                            value="2"
                            sx={{
                                flexGrow: 1,
                                flexBasis: 0,
                            }}
                        />
                        <Tab
                            label="En juego"
                            value="3"
                            sx={{
                                flexGrow: 1,
                                flexBasis: 0,
                            }}
                            disabled={inProgressFixtures?.length === 0}
                        />
                    </TabList>
                </Box>

                <div className="full-calendar" onClick={handleClickCalendar}>
                    <FullCalendarIcon />
                    <Typography>Ver el calendario completo</Typography>
                </div>

                <TabPanel value="1" style={{ padding: '0', overflow: 'auto', maxHeight: maxHeight }}>
                    <div
                        className="fixtures-card-container analitica-box-shadow"
                        style={{ backgroundColor: 'white', padding: '0 8px 8px 8px' }}
                    >
                        {upcomingFixtures?.length > 0 && (
                            <FixturesNewCardComponent
                                fixtures={upcomingFixtures}
                                handleFixture={handleFixture}
                            />
                        )}
                    </div>
                </TabPanel>
                <TabPanel value="2" style={{ padding: '0', overflow: 'auto', maxHeight: maxHeight }}>
                    <div
                        className="fixtures-card-container analitica-box-shadow"
                        style={{ backgroundColor: 'white', padding: '0 8px 8px 8px' }}
                    >
                        {pastFixtures?.length > 0 && (
                            <FixturesNewCardComponent fixtures={pastFixtures} handleFixture={handleFixture} />
                        )}
                    </div>
                </TabPanel>
                <TabPanel value="3" style={{ padding: '0', overflow: 'auto', maxHeight: maxHeight }}>
                    <div
                        className="fixtures-card-container analitica-box-shadow"
                        style={{ backgroundColor: 'white', padding: '0 8px 8px 8px' }}
                    >
                        {inProgressFixtures?.length > 0 && (
                            <FixturesNewCardComponent
                                fixtures={inProgressFixtures}
                                handleFixture={handleFixture}
                            />
                        )}
                    </div>
                </TabPanel>
            </TabContext>
        </Box>
    );
}

export default FixturesCard;
