import { REDUCERS } from '@/constants/reducers';

export function configuration(state, action) {
    let configuration = { ...state.configuration };
    switch (action.type) {
        case REDUCERS.UPDATE_CONFIGURATION:
            return { ...state, configuration: { ...configuration, ...action.payload } };
        default:
            return state;
    }
}
