import { REDUCERS } from '@/constants/reducers';

export function config(state, action) {
    let config = { ...state.config, ...action.payload };
    switch (action.type) {
        case REDUCERS.UPDATE_CONFIG:
            return { ...state, config };
        case REDUCERS.UPDATE_SEASON:
            config.season = action.payload.season;
            return { ...state, config };
        case REDUCERS.UPDATE_LEAGUE:
            config.league = action.payload.league;
            return { ...state, config };
        default:
            return state;
    }
}
