export const SUBSCRIPTION_TYPE = {
    FREE: 0,
    BASIC: 1,
    PREMIUM: 2,
    SUPERADMIN: 3,
};

export const subscriptions = [
    {
        value: -1,
        label: 'Todas',
    },
    {
        value: SUBSCRIPTION_TYPE.FREE,
        label: 'Gratis',
        price: 0,
    },
    {
        value: SUBSCRIPTION_TYPE.BASIC,
        label: 'Básico',
        price: 1.99,
    },
    {
        value: SUBSCRIPTION_TYPE.PREMIUM,
        label: 'Premium',
        price: 4.99,
    },
    {
        value: SUBSCRIPTION_TYPE.SUPERADMIN,
        label: 'Superadmin',
    },
];

export const getSubscriptionLabel = (subscriptionType) => {
    const subscription = subscriptions.find((x) => x.value === subscriptionType);
    return subscription ? subscription.label : '';
};

export const getSubscriptionPrice = (subscriptionType) => {
    const subscription = subscriptions.find((x) => x.value === subscriptionType);
    return subscription ? subscription.price : '';
};
