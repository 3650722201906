// Lazy load del componente LineComponent
import React, { lazy, Suspense } from 'react';
const LineComponent = lazy(() => import('./index'));

const WrapperLineComponent = ({ data, text, options, shouldLoad = true }) => {
    return (
        <div>
            {shouldLoad && (
                <Suspense fallback={<div>Cargando gráfico...</div>}>
                    <LineComponent text={text} data={data} options={options} />
                </Suspense>
            )}
        </div>
    );
};

export default WrapperLineComponent;
