const TwitterIcon = ({ iconWidth, iconHeight, onClick, color, style }) => {
    const handleClick = () => {
        if (onClick) onClick();
    };
    return (
        <svg
            onClick={handleClick}
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 16}
            height={iconHeight ?? 16}
            viewBox="0 0 16 16"
            style={style}
            id="twitter-icon"
        >
            <path
                fill={color ?? '#ffffff'}
                d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07l-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"
            />
        </svg>
    );
};

export default TwitterIcon;
