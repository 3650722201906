export const currencyFormat = (num) => {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €';
};

export const toPriceInEuros = function (number) {
    if (!number || number == 0) {
        return '-';
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €';
};

export const getPriceColor = (price) => {
    if (price < 0) {
        return '#be1212';
    } 
    else if (price > 0) {
        return '#1a892d';
    }
    else {
        return '#000';
    }
}