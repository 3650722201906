import BiwengerScoreComponent from './biwenger-score';

const BiwengerFitness = ({ fitness, width, height }) => {
    return (
        <div style={{ display: 'flex', gap: '1px' }}>
            {fitness?.map((f, index) => (
                <BiwengerScoreComponent score={f} key={index} width={width} height={height} />
            ))}
        </div>
    );
};

export default BiwengerFitness;
