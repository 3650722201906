import React, { useContext, useEffect, useState } from 'react';

import { Skeleton } from '@mui/material';

import { Context } from '@/context';
import { esFechaMayorOIgualActual } from '@/helpers/datetime-helper';

import FixturesCard from './fixtures-card';

const MatchesCardComponent = ({ handleClose, maxHeight }) => {
    const [fixturesToDisplay, setFixturesToDisplay] = useState([]);

    const { state } = useContext(Context);
    const { fixtures, preferences } = state;

    useEffect(() => {
        if (fixtures?.rounds?.length > 0) {
            const rounds = fixtures?.rounds;

            if (!rounds) return;

            let nextRound = rounds
                .filter((x) => x.leagueId === preferences.league && !esFechaMayorOIgualActual(x.date))
                .sort((a, b) => new Date(a.date) - new Date(b.date))?.[0];

            if (!nextRound) {
                nextRound = rounds
                    .filter((x) => x.leagueId === preferences.league)
                    .sort((a, b) => new Date(b.date) - new Date(a.date))?.[0];
            }

            if (!nextRound) {
                nextRound = rounds.sort((a, b) => new Date(b.date) - new Date(a.date))?.[0];
            }

            if (nextRound) {
                setFixturesToDisplay(nextRound.fixtures);
            }
        }
    }, [fixtures?.rounds]);

    const getFixturesData = () => {
        if (fixturesToDisplay?.length > 0) {
            return <FixturesCard handleClose={handleClose} maxHeight={maxHeight} />;
        } else {
            return <Skeleton variant="rectangular" width="100%" height={750} className="mb-25" />;
        }
    };

    return (
        <div className="jornadas-card jornadas-card-only-jornadas" id="matches-card">
            {getFixturesData()}
        </div>
    );
};

export default MatchesCardComponent;
