import { useState, useEffect } from 'react';

import Image from 'next/image';

const CustomImageComponent = ({
    src,
    width,
    height,
    alt,
    className,
    style,
    onClick,
    unoptimized,
    fallbackSrc,
    priority = false,
}) => {
    const defaultImage = `${process.env.NEXT_PUBLIC_IMAGES}/jugadores/0.png`;
    const [imageSrc, setImageSrc] = useState(src || defaultImage);

    // Efecto para actualizar la imagen cuando la prop `src` cambie
    useEffect(() => {
        setImageSrc(src || defaultImage);
    }, [src]);

    const handleError = () => {
        setImageSrc(fallbackSrc || defaultImage);
    };

    return (
        <Image
            src={imageSrc}
            width={width}
            height={height}
            alt={alt ?? 'imagen'}
            className={className}
            style={style}
            onClick={onClick}
            unoptimized={unoptimized ?? true}
            priority={priority}
            onError={handleError}
        />
    );
};

export default CustomImageComponent;
