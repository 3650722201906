const LogoutIcon = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                <path
                    fill="none"
                    stroke="#dc2626"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M23.992 6H6v36h18m9-9l9-9l-9-9m-17 8.992h26"
                />
            </svg>
        </div>
    );
};

export default LogoutIcon;
