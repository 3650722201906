import { useContext } from 'react';

import { Box, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import BiwengerScoreComponent from '@/components/biwenger/biwenger-score';
import { helperEvents } from '@/components/page-components/fixtures/fixture-events/helperEvents';
import CustomImageComponent from '@/components/shared/custom/custom-image';
import FixtureScore from '@/components/shared/fixture-score';
import BiwengerIcon from '@/components/shared/icons/biwenger-icon';
import FantasyLaLigaIcon from '@/components/shared/icons/fantasy-la-liga-icon';
import PicasIcon from '@/components/shared/icons/picas-icon';
import { LEAGUES } from '@/constants/leagues';
import { REDUCERS } from '@/constants/reducers';
import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';
import { translate } from '@/data/fixture-transalations';
import { getTeamById } from '@/data/teams';
import { FANTASY, FANTASY_MODE } from '@/helpers/biwenger-helper';
import { getCompetitionImage } from '@/helpers/leagues-helper';
import URL_FACTORY from '@/helpers/url-helper';

const PartidosTabComponent = ({ player }) => {
    const fixtures = player?.fixtures?.lastFixtures;
    const { state, dispatch } = useContext(Context);
    const { preferences } = state;
    const router = useRouter();

    const getTeamImage = (team) => {
        const teamFound = getTeamById(team?.id);

        const src = teamFound ? URL_FACTORY.GetTeamImageLogo(teamFound?.slug) : team.image;

        return (
            <CustomImageComponent
                style={{ marginRight: '4px' }}
                src={src}
                alt={team.name}
                width={15}
                height={15}
            />
        );
    };

    const getEventForPlayer = (event, playerId, index) => {
        let icon;

        if (event?.type === 'subst') {
            if (event?.player?.id === playerId) {
                icon = helperEvents.getSecondEventIcon(event.type, event.detail);
            }

            if (event?.assist?.id === playerId) {
                icon = helperEvents.getEventIcon(event.type, event.detail, event.isRemoved);
            }
        } else {
            if (event?.player?.id === playerId) {
                icon = helperEvents.getEventIcon(event.type, event.detail, event.isRemoved);
            }

            if (event?.assist?.id === playerId) {
                icon = helperEvents.getSecondEventIcon(event.type, event.detail);
            }
        }

        if (icon) {
            return (
                <Box key={index} style={{ marginRight: '4px', display: 'flex', alignItems: 'center' }}>
                    {icon}
                    <Typography
                        style={{
                            fontSize: '10px',
                            position: 'relative',
                            bottom: '-4px',
                        }}
                    >
                        {event?.time?.elapsed}
                    </Typography>
                </Box>
            );
        }
    };

    const getFixtureResult = (fixture) => {
        const isHome = fixture?.home?.id === player?.team?.id;
        if (fixture?.home?.goals === fixture?.away?.goals) {
            return '#f0f0f0';
        }
        if (
            (fixture?.home?.goals > fixture?.away?.goals && isHome) ||
            (fixture?.home?.goals < fixture?.away?.goals && !isHome)
        ) {
            return '#def1e4';
        } else {
            return '#f7d9d9';
        }
    };

    const getScoreFantasyLaLiga = (fixture) => {
        const roundNumber = fixture?.round?.split('- ')?.[1];

        if (isNaN(roundNumber)) {
            return '';
        }

        const round = parseInt(roundNumber);
        const playerStat = player?.playerStats?.find((x) => x.weekNumber === round);

        if (playerStat) {
            return <FixtureScore score={playerStat.totalPoints} isAvailable={true} />;
        }
    };

    const getFantasyFixtureScore = (fixture) => {
        if (fixture?.leagueId !== LEAGUES.LA_LIGA) {
            return '';
        }

        if (preferences.fantasy === FANTASY.FANTASY) {
            return getScoreFantasyLaLiga(fixture);
        } else if (preferences.fantasy === FANTASY.BIWENGER) {
            const biwengerStat = player?.biwengerStats?.find((x) => x.fixtureId === fixture?.id);
            const point = biwengerStat?.partido?.points?.find((x) => x.id == preferences.mode);

            switch (preferences.mode) {
                case FANTASY_MODE.DIARIO_AS:
                    const picas = point?.picas ?? 0;
                    const picasWidth = 10;
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '4px',
                            }}
                        >
                            <BiwengerScoreComponent score={point?.points} width="25px" height="25px" />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {picas > 0 ? <PicasIcon width={picasWidth} height={picasWidth} /> : null}
                                {picas > 1 ? <PicasIcon width={picasWidth} height={picasWidth} /> : null}
                                {picas > 2 ? <PicasIcon width={picasWidth} height={picasWidth} /> : null}
                                {picas > 3 ? <PicasIcon width={picasWidth} height={picasWidth} /> : null}
                            </div>
                        </div>
                    );
                default:
                    return <BiwengerScoreComponent score={point?.points} width="25px" height="25px" />;
            }
        } else {
            return '-';
        }
    };

    const getPuntosLogo = () => {
        switch (preferences.fantasy) {
            case FANTASY.FANTASY:
                return <FantasyLaLigaIcon iconWidth={25} iconHeight={25} />;
            case FANTASY.BIWENGER:
                return <BiwengerIcon />;
        }
    };

    const redirectToFixture = (fixture) => {
        dispatch({ type: REDUCERS.HIDE_PLAYER_PROFILE });
        const url = Rutas.PARTIDO + '/' + fixture.id + '/alineaciones-probables';
        router.push(url);
    };

    return (
        <div className="partidos-tab">
            <div className="partidos-tab__header">
                <Typography className="partidos-tab__header-1">Partido</Typography>
                <Typography className="partidos-tab__header-2">Eventos</Typography>
                <Typography className="partidos-tab__header-3">{getPuntosLogo()}</Typography>
            </div>
            {fixtures?.map((fixture, index) => {
                return (
                    <div
                        key={index}
                        className="partidos-tab__fixture"
                        onClick={() => redirectToFixture(fixture)}
                    >
                        <div className="partidos-tab__body">
                            <div
                                className="partidos-tab__column partidos-tab__column-first"
                                style={{ backgroundColor: getFixtureResult(fixture) }}
                            >
                                <CustomImageComponent
                                    src={getCompetitionImage(fixture?.league?.id ?? fixture?.leagueId, 15)}
                                    alt="competición"
                                    width={15}
                                    height={15}
                                />
                                <Typography style={{ fontSize: '12px', color: '#0070f3', marginTop: '2px' }}>
                                    {translate(fixture?.round, true)}
                                </Typography>
                            </div>
                            <div className="partidos-tab__column partidos-tab__column-second">
                                <div className="partidos-tab__column-row">
                                    {getTeamImage(fixture?.home)}
                                    <Typography className="partidos-tab__score">
                                        {fixture?.home?.goals}
                                    </Typography>
                                </div>
                                <div className="partidos-tab__column-row">
                                    {getTeamImage(fixture?.away)}
                                    <Typography className="partidos-tab__score">
                                        {fixture?.away?.goals}
                                    </Typography>
                                </div>
                            </div>
                            <div className="partidos-tab__events">
                                {fixture?.events?.map((event, index) =>
                                    getEventForPlayer(event, player.information.id, index)
                                )}
                            </div>
                            <div className="partidos-tab__score-fantasy">
                                <Typography>{getFantasyFixtureScore(fixture)}</Typography>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default PartidosTabComponent;
