const BuscadorJugadoresIcon = ({ iconHeight, iconWidth }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 30}
            height={iconHeight ?? 30}
            viewBox="0 0 14 14"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M1.75 1.5a.25.25 0 0 0-.25.25v1.5a.75.75 0 0 1-1.5 0v-1.5A1.75 1.75 0 0 1 1.75 0h1.5a.75.75 0 0 1 0 1.5zm11.5 8.5a.75.75 0 0 1 .75.75v1.5A1.75 1.75 0 0 1 12.25 14h-1.5a.75.75 0 0 1 0-1.5h1.5a.25.25 0 0 0 .25-.25v-1.5a.75.75 0 0 1 .75-.75m-11.75.75a.75.75 0 0 0-1.5 0v1.5A1.75 1.75 0 0 0 1.75 14h1.5a.75.75 0 0 0 0-1.5h-1.5a.25.25 0 0 1-.25-.25zm8.5-10a.75.75 0 0 1 .75-.75h1.5A1.75 1.75 0 0 1 14 1.75v1.5a.75.75 0 0 1-1.5 0v-1.5a.25.25 0 0 0-.25-.25h-1.5A.75.75 0 0 1 10 .75M6.25 4a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5M2.5 6.25a3.75 3.75 0 1 1 6.879 2.068L11.03 9.97a.75.75 0 1 1-1.06 1.06L8.318 9.38A3.75 3.75 0 0 1 2.5 6.25"
                clipRule="evenodd"
            />
        </svg>
    );
}

export default BuscadorJugadoresIcon;