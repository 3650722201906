const MaterialTabPanel = ({ children, value, index, styles, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            style={styles}
        >
            {value === index && <div className="main-tabs-content">{children}</div>}
        </div>
    );
};

export default MaterialTabPanel;
