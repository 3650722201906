let uniqueIndexCounter = 0;

const MaterialTabProps = ({ index }) => {
    const getUniqueIndex = () => {
        if (typeof index !== 'undefined') {
            return index;
        }
        // Generar un valor único basado en un contador global
        return uniqueIndexCounter++;
    };

    return {
        id: `full-width-tab-${getUniqueIndex()}`,
        'aria-controls': `full-width-tabpanel-${getUniqueIndex()}`,
    };
};

export default MaterialTabProps;
