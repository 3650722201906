import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#00793d',
        },
        tertiary: {
            main: '#1587c0',
            contrastText: '#fff',
        },
        disabled: {
            main: '#9e9e9e',
        },
        error: {
            main: '#d32f2f',
        },
        warning: {
            main: '#d15b00',
        },
        delete: {
            main: '#fff',
            contrastText: '#d32f2f',
        },
        link: {
            main: '#0d6efd',
        },
        analitica: {
            main: '#22272F',
            contrastText: '#fff',
        },
        tabs: {
            main: '#22272F',
        },
        white: {
            main: '#fff',
        },
        tabsDecorator: {
            main: '#1976d2',
        },
        white: {
            main: '#fff',
        },
        black: {
            main: '#000',
        },
        risks: {
            veryHigh: 'rgb(242 127 127)',
            high: 'rgb(208 172 108)',
            medium: '#dddddd',
            low: 'rgb(148 191 148)',
            veryLow: 'rgb(192 216 237)',
        },
        minutes: {
            veryHigh: '#2196f3',
            high: '#0C6',
            medium: '#ccc',
            low: '#F90',
            veryLow: '#d32f2f',
        },
        competitions: {
            laLiga: '#ff4b44',
            fifaFriendlies: '#4db7ad',
            championsLeague: '#1d6fb1',
            supercopaEspaña: '#F66A03',
            copaRey: 'rgb(229 184 105)',
            europaLeague: '#1a892d',
            euroClasificacion: '#158ec0',
            defaultColor: '#69b59f',
        },
    },
    typography: {
        allVariants: {
            fontFamily: `'Roboto Condensed', sans-serif`,
            textTransform: 'none',
            fontSize: 16,
        },
    },
});
