import { REDUCERS } from '@/constants/reducers';

export function cacheState(state, action) {
    switch (action.type) {
        case REDUCERS.UPDATE_CACHE:
            const cacheState = { ...state.cacheState, ...action.payload };
            return { ...state, cacheState };
        default:
            return state;
    }
}
