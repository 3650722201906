export const POSITION_COLORS = {
    PORTERO: '#1d6fb1',
    DEFENSA: '#1a892d',
    MEDIOCAMPISTA: '#812c7c',
    DELANTERO: '#be1212',
    ENTRENADOR: '#0f0f0f',
};

export const POSITION_NAMES = {
    PORTERO: 1,
    DEFENSA: 2,
    MEDIOCAMPISTA: 3,
    DELANTERO: 4,
    ENTRENADOR: 5,
};
