import React, { useState, useEffect } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Button,
    Typography,
    Collapse,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    TextField,
    Grid,
    Box,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';


import { LEAGUES } from '@/constants/leagues';
import { Rutas } from '@/constants/rutas';
import { ACTIVITY_TYPE, activityTypes } from '@/data/activities';
import { obtenerEquipos } from '@/data/equipos';
import { getAuthorLogo } from '@/helpers/author-helper';
import { formatDateToDDMMYYYY, getTimeAgo } from '@/helpers/datetime-helper';
import { getMasterPhotoById, getTeamImageById } from '@/helpers/images-helper';
import { replaceDiacritis } from '@/helpers/players-helper';

import CustomImageComponent from '../shared/custom/custom-image';
import CalendarIcon from '../shared/icons/calendar-icon';
import LazyLoadWrapper from '../shared/wrappers/lazy-load-wrapper';

import AlineacionesActivityIcon from './icons/alineaciones-activity-icon';
import EntrenamientoGymIcon from './icons/entrenamiento-gym-icon';
import ActivityInfoIcon from './icons/info-icon';
import MercadoActivityIcon from './icons/mercado-activity-icon';
import NoticiaIcon from './icons/noticia-icon';
import RdpIcon from './icons/rdp-icon';
import RecomendacionIcon from './icons/recomendacion-icon';
import VerifiedIcon from './icons/verified-icon';

export const getIcon = (activity) => {
    const size = 25;
    switch (activity?.type) {
        case ACTIVITY_TYPE.TEAM:
            if (activity?.team) {
                return (
                    <CustomImageComponent
                        src={getTeamImageById(activity?.team, 38)}
                        alt="equipo"
                        width={size}
                        height={size}
                    />
                );
            } else {
                return <ActivityInfoIcon />;
            }
        case ACTIVITY_TYPE.PLAYER:
            if (activity?.player?.id > 0) {
                return (
                    <CustomImageComponent
                        src={getMasterPhotoById(activity?.player?.id, 60)}
                        alt={activity?.player?.name ?? 'jugador'}
                        width={size}
                        height={size}
                    />
                );
            } else {
                return <ActivityInfoIcon />;
            }
        case ACTIVITY_TYPE.INFO:
            return <ActivityInfoIcon width={size} height={size} />;
        case ACTIVITY_TYPE.NOTICIA:
            return <NoticiaIcon width={size} height={size} />;
        case ACTIVITY_TYPE.ENTRENAMIENTO:
            return <EntrenamientoGymIcon width={size} height={size} />;
        case ACTIVITY_TYPE.GYM:
            return <EntrenamientoGymIcon width={size} height={size} />;
        case ACTIVITY_TYPE.VERIFIED:
            return <VerifiedIcon width={size} height={size} />;
        case ACTIVITY_TYPE.RECOMENDACION:
            return <RecomendacionIcon width={size} height={size} />;
        case ACTIVITY_TYPE.ALINEACIONES:
            return <AlineacionesActivityIcon width={size} height={size} />;
        case ACTIVITY_TYPE.MERCADO:
            return <MercadoActivityIcon width={size} height={size} />;
        case ACTIVITY_TYPE.RDP:
            return <RdpIcon width={size} height={size} />;
        default:
            return null;
    }
};

const HomeActivities = ({
    isPage,
    style,
    customActivities,
    customTake,
    loadMoreActividades,
    fetchActivities,
    showHeader = true,
    showFilters = true,
}) => {
    const [actividades, setActividades] = useState(customActivities);
    const [groupedActivities, setGroupedActivities] = useState(null);
    const router = useRouter();

    const [expandedActivityId, setExpandedActivityId] = useState(null);
    const [selectedType, setSelectedType] = useState('ALL');
    const [selectedTeam, setSelectedTeam] = useState('ALL');
    const [searchKeyword, setSearchKeyword] = useState('');

    const toggleActivity = (id) => {
        setExpandedActivityId((prevId) => (prevId === id ? null : id));
    };

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };

    const handleTeamChange = (event) => {
        setSelectedTeam(event.target.value);
    };

    const handleSearchKeywordChange = (event) => {
        setSearchKeyword(event.target.value);
    };

    const getActionBtn = () => {
        if (isPage) {
            return (
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                        const skip = Number(actividades?.length);

                        if (loadMoreActividades) {
                            loadMoreActividades(skip, 100);
                        }
                    }}
                >
                    Ver más actividades
                </Button>
            );
        } else {
            return (
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => router.push(Rutas.ACTIVIDADES)}
                >
                    Ver todas las actividades
                </Button>
            );
        }
    };

    const buildGroupedActivities = (actividades) => {
        const newActivities = actividades
            ?.filter((_, index) => !customTake || index < customTake)
            ?.reduce((acc, activity) => {
                const date = new Date(activity.created).toDateString();
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(activity);
                return acc;
            }, {});
        setGroupedActivities(newActivities);
    };

    useEffect(() => {
        if (customActivities?.length > 0) {
            setActividades(customActivities);
            buildGroupedActivities(customActivities);
        }
    }, [customActivities]);

    useEffect(() => {
        const activitiesLoaded = customActivities?.length > 0;
        if (fetchActivities && !activitiesLoaded) {
            fetchActivities();
        }
    }, [fetchActivities]);

    const getFilteredActivities = () => {
        if (!groupedActivities) return null;

        return Object.entries(groupedActivities).map(([date, activities]) => {
            let filteredActivities = activities;

            if (selectedType !== 'ALL') {
                filteredActivities = filteredActivities.filter((activity) => activity.type === selectedType);
            }

            if (selectedTeam !== 'ALL') {
                filteredActivities = filteredActivities.filter((activity) => activity.team == selectedTeam);
            }

            if (searchKeyword !== '') {
                filteredActivities = filteredActivities.filter((activity) =>
                    replaceDiacritis(activity.title).includes(replaceDiacritis(searchKeyword))
                );
            }

            if (filteredActivities.length === 0) return null;

            return (
                <React.Fragment key={date}>
                    <div
                        className="home-activities__date"
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <CalendarIcon iconWidth={15} iconHeight={15} color={'#000'} />
                            {formatDateToDDMMYYYY(date)}
                        </div>
                        <div>{filteredActivities.length} actividades</div>
                    </div>
                    {filteredActivities.map((activity) => {
                        return (
                            <LazyLoadWrapper key={activity.id}>
                                <div className="home-activities__activity-item">
                                    <div
                                        className="home-activities__title"
                                        onClick={() => toggleActivity(activity.id)}
                                    >
                                        <div>
                                            {expandedActivityId === activity.id ? (
                                                <ExpandLessIcon />
                                            ) : (
                                                <ExpandMoreIcon />
                                            )}
                                        </div>
                                        <div className="home-activities__icon">{getIcon(activity)}</div>
                                        <Typography className="home-activities__title-text">
                                            {activity.title}
                                        </Typography>
                                    </div>
                                    <Collapse
                                        in={expandedActivityId === activity.id}
                                        timeout="auto"
                                        unmountOnExit
                                        aria-label="Actividades de equipo"
                                        style={{ width: '100%' }}
                                    >
                                        <div className="home-activities__activity-content">
                                            <div style={{ paddingLeft: '40px', fontSize: '14px' }}>
                                                {activity.content}
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                {activity?.isClickable && (
                                                    <Typography
                                                        style={{ fontSize: '12px' }}
                                                        className="home-activities__link"
                                                    >
                                                        <Link href={activity.url}>Ver más</Link>
                                                    </Typography>
                                                )}
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        display: 'flex',
                                                        gap: '4px',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <CustomImageComponent
                                                        src={getAuthorLogo(activity.author)}
                                                        alt="autor"
                                                        width={25}
                                                        height={25}
                                                    />
                                                    {getTimeAgo(activity.created)}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </LazyLoadWrapper>
                        );
                    })}
                </React.Fragment>
            );
        });
    };

    return (
        <div className="home-activities">
            {showHeader && <h2 className="home-activities__title">Actividades de fantasy fútbol</h2>}
            {showFilters && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth variant="standard" margin="normal">
                            <InputLabel id="activity-type-label">Tipo de Actividad</InputLabel>
                            <Select
                                labelId="activity-type-label"
                                value={selectedType}
                                onChange={handleTypeChange}
                                label="Tipo de Actividad"
                            >
                                <MenuItem value="ALL">Todas</MenuItem>
                                {activityTypes
                                    .filter((x) => !x.exclude)
                                    .map((type) => (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth variant="standard" margin="normal">
                            <InputLabel id="team-filter-label">Equipo</InputLabel>
                            <Select
                                labelId="team-filter-label"
                                value={selectedTeam}
                                onChange={handleTeamChange}
                                label="Equipo"
                            >
                                <MenuItem value="ALL">Todos los equipos</MenuItem>
                                {obtenerEquipos(LEAGUES.LA_LIGA).map((team) => (
                                    <MenuItem key={team.id} value={team.id}>
                                        {team.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Título"
                            variant="standard"
                            fullWidth
                            margin="normal"
                            value={searchKeyword}
                            onChange={handleSearchKeywordChange}
                        />
                    </Grid>
                </Grid>
            )}

            <div style={{ ...style, overflowY: 'auto' }}>
                <Box>{getFilteredActivities()}</Box>
            </div>
            {getActionBtn()}
        </div>
    );
};

export default HomeActivities;
