import { Typography } from '@mui/material';

import AlertInfoIcon from './icons/custom-alert/alert-info-icon';

const CustomAlertActividades = ({ text, onClick }) => {
    const getIcon = () => {
        return <AlertInfoIcon style={{ width: '20px' }} />;
    };

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
            }}
        >
            <div style={{ width: '25px', display: 'flex', justifyContent: 'center' }}>{getIcon()}</div>

            <Typography
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 'calc(100% - 85px)',
                    display: 'block',
                    alignItems: 'center',
                    fontSize: '14px',
                    margin: 0,
                    padding: 0,
                    position: 'relative',
                    top: '1px',
                }}
            >
                {text}
            </Typography>

            <div style={{ width: '50px', textAlign: 'right' }}>
                <small
                    style={{
                        color: '#0645AD ',
                        cursor: 'pointer',
                        fontSize: '12px',
                        textDecoration: 'underline',
                    }}
                    onClick={handleClick}
                >
                    Ver más
                </small>
            </div>
        </div>
    );
};

export default CustomAlertActividades;
