import { Fragment } from 'react';

import { Typography } from '@mui/material';
import { useRouter } from 'next/router';

import { LEAGUES } from '@/constants/leagues';
import { Rutas } from '@/constants/rutas';
import { obtenerEquipos, toTeamSlug } from '@/data/equipos';
import { getTeamImageById } from '@/helpers/images-helper';

import CustomImageComponent from './custom/custom-image';

const TeamLogoComponent = () => {
    const router = useRouter();

    const handleClick = (team) => {
        router.push(`${Rutas.EQUIPO}/${team.id}${toTeamSlug(team.name)}`);
    };

    return (
        <Fragment>
            <div className="team-logo-container">
                <nav className="container">
                    <ul className="team-logo-container__subcontainer" style={{ listStyle: 'none' }}>
                        {obtenerEquipos(LEAGUES.LA_LIGA).map((team, index) => (
                            <li
                                key={index}
                                onClick={() => handleClick(team)}
                                style={{ position: 'relative' }}
                            >
                                <CustomImageComponent
                                    width={40}
                                    height={40}
                                    className="team-logo-container__image"
                                    src={getTeamImageById(team.id, 60, 60)}
                                    alt={`logo-${team.name}`}
                                    loading="eager"
                                />
                                {team.group && (
                                    <Typography
                                        style={{
                                            position: 'absolute',
                                            right: '-8px',
                                            bottom: '-4px',
                                            color: '#fff',
                                            fontSize: '10px',
                                            opacity: 0.55,
                                        }}
                                    >
                                        {team.group}
                                    </Typography>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </Fragment>
    );
};

export default TeamLogoComponent;
