import { REDUCERS } from '@/constants/reducers';

export function preferences(state, action) {
    let preferences = { ...state.preferences, ...action.payload };
    switch (action.type) {
        case REDUCERS.UPDATE_PREFERENCES:
            return { ...state, preferences };
        case REDUCERS.GET_PREFERENCES:
            return { ...state, preferences };
        default:
            return state;
    }
}
