const CreadoresContenidoIcon = ({ iconWidth, iconHeight }) => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={iconWidth ?? 30}
                height={iconHeight ?? 30}
                viewBox="0 0 48 48">
                <g fill="none" stroke="#ffffff" stroke-linejoin="round" stroke-width="4"><path d="M4 10a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2z" /><path stroke-linecap="round" d="M36 8v32M12 8v32m26-22h6m-6 12h6M4 18h6m-6-2v4M9 8h6M9 40h6M33 8h6m-6 32h6M4 30h6m-6-2v4m40-4v4m0-16v4" /><path d="m21 19l8 5l-8 5z" /></g></svg>
        </div>
    );
}

export default CreadoresContenidoIcon;