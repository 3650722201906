import BiwengerIcon from '@/components/shared/icons/biwenger-icon';
import FantasyLaLigaIcon from '@/components/shared/icons/fantasy-la-liga-icon';

export const biwengerScores = [
    {
        id: 1,
        name: 'Diario AS',
        kind: 'as',
    },
    {
        id: 2,
        name: 'SofaScore',
        kind: 'SofaScore',
    },
    {
        id: 3,
        name: 'Estadísticas',
        kind: 'Stats',
    },
    {
        id: 5,
        name: 'Media AS y SofaScore',
        kind: 'Average',
    },
    {
        id: 6,
        name: 'Biwenger Social',
        kind: 'Social',
    },
];

export const FANTASY = {
    INFORMATION: 0,
    ANALITICA: 1,
    FANTASY: 2,
    BIWENGER: 3,
};

export const FANTASY_MODE = {
    TITULARIDAD: 0,
    DIARIO_AS: 1,
    SOFASCORE: 2,
    STATS: 3,
    AVERAGE: 5,
    SOCIAL: 6,
    CARRUSEL_DEPORTIVO: 7,
};

export const fantasyOptions = [
    {
        fantasy: 2,
        mode: 1,
        value: 'Fantasy',
        label: 'Fantasy La Liga',
        icon: <FantasyLaLigaIcon style={{ marginRight: '4px' }} iconWidth={25} iconHeight={25} />,
    },
    {
        fantasy: 3,
        mode: 1,
        value: 'Diario AS',
        label: 'Diario AS',
        icon: <BiwengerIcon style={{ marginRight: '4px' }} width={25} height={25} />,
    },
    {
        fantasy: 3,
        mode: 2,
        value: 'SofaScore',
        label: 'SofaScore',
        icon: <BiwengerIcon style={{ marginRight: '4px' }} width={25} height={25} />,
    },
    {
        fantasy: 3,
        mode: 3,
        value: 'Estadísticas',
        label: 'Estadísticas',
        icon: <BiwengerIcon style={{ marginRight: '4px' }} width={25} height={25} />,
    },
    {
        fantasy: 3,
        mode: 5,
        value: 'Media AS y SofaScore',
        label: 'Media AS y SofaScore',
        icon: <BiwengerIcon style={{ marginRight: '4px' }} width={25} height={25} />,
    },
    {
        fantasy: 3,
        mode: 6,
        value: 'Biwenger Social',
        label: 'Biwenger Social',
        icon: <BiwengerIcon style={{ marginRight: '4px' }} width={25} height={25} />,
    },
];

export const BIWENGER_IMAGES = {
    PLAYER: 'https://cdn.biwenger.com/i/p',
    TEAM: 'https://cdn.biwenger.com/i/t',
};
