import DiscardedIcon from '../shared/icons/status-icons/discarded-icon';
import InjuredStatusIcon from '../shared/icons/status-icons/injured-status-icon';
import QuestionMarkIcon from '../shared/icons/status-icons/question-mark-icon';

const BiwengerScoreComponent = ({ score, width = '20px', height = '20px' }) => {
    const styles = {
        width: width,
        height: height,
        borderRight: '1px solid #fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        borderRadius: '4px',
        fontSize: '12px',
    };

    const getColor = () => {
        if (!score && score !== 0) return '#ccc';
        if (score < 0) {
            return 'red';
        } else if (score < 6) {
            return '#ff9800';
        } else if (score < 10) {
            return '#10af00';
        } else {
            return '#03a9f4';
        }
    };
    const getScoreIcon = () => {
        if (score === null) return <div style={{ ...styles, background: '#ccc' }}>-</div>;
        //check if score is a number
        if (!isNaN(score)) {
            const color = getColor();
            return <div style={{ ...styles, background: color }}>{score}</div>;
        }

        switch (score) {
            case 'injured':
                return <InjuredStatusIcon iconWidth={20} iconHeight={20} />;
            case 'doubt':
                return <QuestionMarkIcon iconHeight={16} iconWidth={16} color="#ea580c" />;
            case 'sanctioned':
                return <div style={{ ...styles, background: '#d9534f' }}></div>;
            case 'discarded':
                return <DiscardedIcon width={20} height={20} />;
            default:
                return (
                    <div
                        style={{
                            width: width,
                            height: height,
                            border: '1px solid rgba(0,0,0,.1)',
                            borderRadius: '4px',
                        }}
                    >
                        {score}
                    </div>
                );
        }
    };

    return getScoreIcon();
};

export default BiwengerScoreComponent;
