import { Fragment } from 'react';

import { Drawer } from '@mui/material';

import MatchesCardComponent from '../page-components/matches-card/matches-card';

const RightDrawerComponent = ({ anchor, open, handleClose }) => {
    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        handleClose();
    };

    return (
        <Fragment key={anchor}>
            <Drawer
                anchor={anchor}
                open={open}
                onClose={toggleDrawer(anchor, false)}
                className="drawer-right"
            >
                <div className={`right-drawer-component`}>
                    <div className="right-drawer-component-content">
                        <MatchesCardComponent handleClose={handleClose} maxHeight="calc(100vh - 230px)" />
                    </div>
                </div>
            </Drawer>
        </Fragment>
    );
};

export default RightDrawerComponent;
