const AdminSyncIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 30}
            height={iconHeight ?? 30}
            viewBox="0 0 24 24"
        >
            <g fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round">
                <path d="M3 12a9 9 0 0 1 16-5.658" />
                <path d="M19.5 3v4h-4m5.5 5a9 9 0 0 1-16 5.657" />
                <path d="M4.5 21v-4h4" />
            </g>
        </svg>
    );
};

export default AdminSyncIcon;
