import { Button } from '@mui/material';

const ButtonMaterial = ({ text, onClick, variant, color, styles, icon, classes, disabled, fullWidth }) => {
    const handleOnClick = (event) => {
        event.preventDefault();
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <Button
            color={color ?? 'primary'}
            variant={variant ?? 'contained'}
            onClick={handleOnClick}
            className={`button-material ${classes}`}
            style={styles}
            disabled={disabled ?? false}
            fullWidth={fullWidth ?? false}
        >
            {icon}
            {text}
        </Button>
    );
};

export default ButtonMaterial;
