const MercadoFichajesLive = ({ width, height }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 25} height={height ?? 25} viewBox="0 0 24 24">
            <g fill="none" stroke="currentColor" strokeWidth="1.5">
                <path strokeLinecap="round" d="M22 22H2m18 0V11M4 22V11" />
                <path
                    strokeLinejoin="round"
                    d="M16.528 2H7.472c-1.203 0-1.804 0-2.287.299c-.484.298-.753.836-1.29 1.912L2.49 7.76c-.324.82-.608 1.786-.062 2.479A2 2 0 0 0 6 9a2 2 0 1 0 4 0a2 2 0 1 0 4 0a2 2 0 1 0 4 0a2 2 0 0 0 3.571 1.238c.546-.693.262-1.659-.062-2.479l-1.404-3.548c-.537-1.076-.806-1.614-1.29-1.912C18.332 2 17.731 2 16.528 2Z"
                />
                <path
                    strokeLinecap="round"
                    d="M9.5 21.5v-3c0-.935 0-1.402.201-1.75a1.5 1.5 0 0 1 .549-.549C10.598 16 11.065 16 12 16s1.402 0 1.75.201a1.5 1.5 0 0 1 .549.549c.201.348.201.815.201 1.75v3"
                />
            </g>
        </svg>
    );
};

export default MercadoFichajesLive;
