import { useEffect, useState, useRef } from 'react';

import VideooRefresherDynamic from '../videoo/videoo-refresher-dynamic';

const PlacementTopDesktopComponent = () => {
    const adContainerRef = useRef(null);
    const [isHbAdLoaded, setIsHbAdLoaded] = useState(true); // Inicializa en true para mostrar hb de entrada

    useEffect(() => {
        // Tiempo de espera para verificar si el anuncio de HB se cargó
        const timer = setTimeout(() => {
            const adContainer = adContainerRef.current;
            if (adContainer) {
                const adFrame = adContainer.querySelector('iframe');
                const adLoaded = adFrame && adFrame.offsetHeight > 0 && adFrame.offsetWidth > 0;

                // Cambia a Videoo si no hay un anuncio cargado en hb
                if (!adLoaded) {
                    setIsHbAdLoaded(false);
                }
            }
        }, 2000); // Espera de 3 segundos antes de verificar

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="placement-ad-template">
            {isHbAdLoaded ? (
                <div className="hb-ad-inarticle d-flex-center">
                    <div className="hb-ad-inner">
                        <div className="hbagency_cls" id="hbagency_space_177071" ref={adContainerRef}></div>
                    </div>
                </div>
            ) : (
                <VideooRefresherDynamic id={4} />
            )}
        </div>
    );
};

export default PlacementTopDesktopComponent;
