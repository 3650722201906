import { useState, useEffect, useContext } from 'react';

import { useRouter } from 'next/router';

import { Context } from '@/context';

import Logo from './logo';

const SpinnerLayout = ({ children }) => {
    const router = useRouter();
    const [isLoadingRoute, setIsLoadingRoute] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

    const { state } = useContext(Context);
    const { app } = state;

    useEffect(() => {
        const handleStart = () => setIsLoadingRoute(true);
        const handleComplete = () => setIsLoadingRoute(false);

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleComplete);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleComplete);
        };
    }, [router]);

    // Si isFallback es true, también mostramos el spinner
    useEffect(() => {
        if (router.isFallback) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [router.isFallback]);

    useEffect(() => {
        if (app.isSpinnerVisible) {
            setIsSpinnerVisible(true);
        } else {
            setIsSpinnerVisible(false);
        }
    }, [app.isSpinnerVisible]);

    return (
        <div>
            {(isLoading || isLoadingRoute || isSpinnerVisible) && (
                <div className="spinner-container">
                    <div className="spinner-overlay">
                        <div className="football-spinner">
                            <Logo width={150} disableClick={true} />
                        </div>
                    </div>
                </div>
            )}

            {children}
        </div>
    );
};

export default SpinnerLayout;
