const PujaIdealIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 25}
            height={iconHeight ?? 25}
            viewBox="0 0 100 100"
        >
            <path fill="#EBEDEE" d="M4 0h92a4 4 0 0 1 4 4v89a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4" />
            <path
                fill="#BDC3C7"
                d="M12 7h76a4 4 0 0 1 4 4v15a4 4 0 0 1-4 4H12a4 4 0 0 1-4-4V11a4 4 0 0 1 4-4"
            />
            <path
                fill="#3498DB"
                d="M12 35h17a4 4 0 0 1 4 4v15a4 4 0 0 1-4 4H12a4 4 0 0 1-4-4V39a4 4 0 0 1 4-4m30 0h17a4 4 0 0 1 4 4v15a4 4 0 0 1-4 4H42a4 4 0 0 1-4-4V39a4 4 0 0 1 4-4M12 65h17a4 4 0 0 1 4 4v15a4 4 0 0 1-4 4H12a4 4 0 0 1-4-4V69a4 4 0 0 1 4-4m30 0h17a4 4 0 0 1 4 4v15a4 4 0 0 1-4 4H42a4 4 0 0 1-4-4V69a4 4 0 0 1 4-4"
            />
            <path
                fill="#34495E"
                d="M72 65h16a4 4 0 0 1 4 4v15a4 4 0 0 1-4 4H72a4 4 0 0 1-4-4V69a4 4 0 0 1 4-4"
            />
            <path
                fill="#fff"
                d="M84.5 78h-9a1.5 1.5 0 0 0 0 3h9a1.5 1.5 0 0 0 0-3m-9-3h9a1.5 1.5 0 0 0 0-3h-9a1.5 1.5 0 0 0 0 3"
            />
            <path
                fill="#E74C3C"
                d="M72 35h16a4 4 0 0 1 4 4v15a4 4 0 0 1-4 4H72a4 4 0 0 1-4-4V39a4 4 0 0 1 4-4"
            />
            <path
                fill="#fff"
                d="M74.999 46.5c0-3.032 2.782-5.5 6.2-5.5h1.49c.724 0 1.311.896 1.311 1.537s-.587 1.412-1.311 1.412h-1.49c-1.975 0-3.205.8-3.205 2.551s1.23 2.488 3.205 2.488h1.49c.724 0 1.373.833 1.373 1.475S83.413 52 82.689 52h-1.49c-3.418 0-6.2-2.467-6.2-5.5M25.5 45H22v-3.5a1.5 1.5 0 1 0-3 0V45h-3.5a1.5 1.5 0 0 0 0 3H19v3.5a1.5 1.5 0 0 0 3 0V48h3.5a1.5 1.5 0 1 0 0-3m0 30h-10a1.5 1.5 0 0 0 0 3h10a1.5 1.5 0 1 0 0-3m30 0h-10a1.5 1.5 0 0 0 0 3h10a1.5 1.5 0 0 0 0-3m-5-2a1.5 1.5 0 1 0-.001-3.001A1.5 1.5 0 0 0 50.5 73m0 7a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 50.5 80m5.02-30.792l-2.725-2.725l2.697-2.696l-.001-.001h.001a1.634 1.634 0 1 0-2.312-2.312l-2.697 2.697l-2.697-2.697a1.634 1.634 0 1 0-2.312 2.312v.001l2.697 2.696l-2.696 2.696l-.001.001a1.636 1.636 0 0 0 2.312 2.312l2.697-2.697l2.724 2.725l.004-.002a1.63 1.63 0 0 0 2.304-2.305z"
            />
            <path
                fill="#7F8C8D"
                d="M85 20v4l1.562 1L88 24v-4l-1.438-1zm1.562-7.999L85 13v4l1.562 1L88 17v-4zM79 20l-1.438-1L76 20v4l1.562 1L79 24zm0-3v-4l-1.438-.999L76 13v4l1.562 1zm5-4l2-1.438L84 10h-4l-2 1.562L80 13zm-4 11l-2 1.562L80 27h4l2-1.438L84 24z"
            />
            <path
                fill="#95A5A6"
                d="M88 7H12a4 4 0 0 0-4 4v2a4 4 0 0 1 4-4h76a4 4 0 0 1 4 4v-2a4 4 0 0 0-4-4"
            />
            <path
                fill="#C0392C"
                d="M88 58H72a4 4 0 0 1-4-4v2a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4v-2a4 4 0 0 1-4 4"
            />
            <path
                fill="#2C3E50"
                d="M88 88H72a4 4 0 0 1-4-4v2a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4v-2a4 4 0 0 1-4 4"
            />
            <path
                fill="#2980B9"
                d="M59 58H42a4 4 0 0 1-4-4v2a4 4 0 0 0 4 4h17a4 4 0 0 0 4-4v-2a4 4 0 0 1-4 4m0 30H42a4 4 0 0 1-4-4v2a4 4 0 0 0 4 4h17a4 4 0 0 0 4-4v-2a4 4 0 0 1-4 4m-30 0H12a4 4 0 0 1-4-4v2a4 4 0 0 0 4 4h17a4 4 0 0 0 4-4v-2a4 4 0 0 1-4 4m0-30H12a4 4 0 0 1-4-4v2a4 4 0 0 0 4 4h17a4 4 0 0 0 4-4v-2a4 4 0 0 1-4 4"
            />
            <path
                fill="#BDC3C7"
                d="M96 97H4a4 4 0 0 1-4-4v3a4 4 0 0 0 4 4h92a4 4 0 0 0 4-4v-3a4 4 0 0 1-4 4"
            />
        </svg>
    );
};

export default PujaIdealIcon;
