export const activityTypes = [
    { label: 'Por defecto', value: 0, exclude: true },
    { label: 'Equipo', value: 1 },
    { label: 'Jugador', value: 2 },
    { label: 'Noticia', value: 3 },
    { label: 'Info', value: 4 },
    { label: 'Entrenamiento', value: 5 },
    { label: 'Gym', value: 6 },
    { label: 'Verificado', value: 7, exclude: true },
    { label: 'Recomendacion', value: 8, exclude: true },
    { label: 'Alineaciones', value: 9, exclude: true },
    { label: 'Mercado', value: 10 },
    { label: 'Rueda de prensa', value: 11 },
];

export const ACTIVITY_TYPE = {
    DEFAULT: 0,
    TEAM: 1,
    PLAYER: 2,
    NOTICIA: 3,
    INFO: 4,
    ENTRENAMIENTO: 5,
    GYM: 6,
    VERIFIED: 7,
    RECOMENDACION: 8,
    ALINEACIONES: 9,
    MERCADO: 10,
    RDP: 11,
};
