import { useContext, useEffect, useState } from 'react';

import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Divider, Typography } from '@mui/material';


import { Context } from '@/context';
import { FANTASY } from '@/helpers/biwenger-helper';
import { toPriceInEuros } from '@/helpers/prices-helper';

import BiwengerFitness from '../biwenger/biwenger-fitness';

const MasterPlayerFantasy = ({ player }) => {
    const [biwengerSelected, setBiwengerSelected] = useState(false);
    const { state } = useContext(Context);
    const { preferences } = state;

    const getPrice = () => {
        if (preferences.fantasy === FANTASY.BIWENGER) {
            const biwenger = player?.biwengerEstadisticas?.find(
                (x) => x.mode === preferences.mode
            )?.estadisticas;

            return toPriceInEuros(biwenger?.price);
        }

        return toPriceInEuros(player?.fantasyPlayer?.information?.marketValue);
    };

    const getScore = () => {
        if (preferences.fantasy === FANTASY.BIWENGER) {
            const biwenger = player?.biwengerEstadisticas?.find(
                (x) => x.mode === preferences.mode
            )?.estadisticas;

            return biwenger?.points ?? '-';
        }

        return player?.fantasyPlayer?.information?.points ?? '-';
    };

    const getMedia = () => {
        if (preferences.fantasy === FANTASY.BIWENGER) {
            const biwenger = player?.biwengerEstadisticas?.find(
                (x) => x.mode === preferences.mode
            )?.estadisticas;

            const played = (biwenger?.playedHome ?? 0) + (biwenger?.playedAway ?? 0);

            const average = biwenger?.points / played;
            return average?.toFixed(2) ?? '-';
        }

        return player?.fantasyPlayer?.information?.averagePoints?.toFixed(2);
    };

    const getFantasyPosition = () => {
        let positionId;
        if (preferences.fantasy === FANTASY.BIWENGER) {
            positionId = player?.biwengerMarketValue?.positionId;
        }

        positionId = player?.fantasyPlayer?.information?.positionId;

        switch (positionId) {
            case 1:
                return 'Portero';
            case 2:
                return 'Defensa';
            case 3:
                return 'Centrocampista';
            case 4:
                return 'Delantero';
            default:
                return '-';
        }
    };

    useEffect(() => {
        if (!player || preferences.fantasy !== FANTASY.BIWENGER) return;

        const biwenger = player?.biwengerEstadisticas?.find((x) => x.mode === preferences.mode)?.estadisticas;
        setBiwengerSelected(biwenger);
    }, [player, preferences]);

    return (
        <div className="master-player-fantasy">
            <div className="master-player-fantasy__column">
                <Typography className="master-player-fantasy__column-top">Precio</Typography>
                <Typography className="master-player-fantasy__column-bottom">{getPrice()}</Typography>
            </div>
            <div className="master-player-fantasy__column">
                <Typography className="master-player-fantasy__column-top">Puntos</Typography>
                <Typography className="master-player-fantasy__column-bottom">
                    <span>{getScore()}</span>
                    {preferences.fantasy === FANTASY.BIWENGER && (
                        <>
                            <HomeIcon style={{ fontSize: '14px' }} />
                            <span>{biwengerSelected?.pointsHome}</span>
                            <FlightTakeoffIcon style={{ fontSize: '14px', marginLeft: '4px' }} />
                            <span>{biwengerSelected?.pointsAway}</span>
                        </>
                    )}
                </Typography>
            </div>
            <div className="master-player-fantasy__column">
                <Typography className="master-player-fantasy__column-top">Media</Typography>
                <Typography className="master-player-fantasy__column-bottom">{getMedia()}</Typography>
            </div>
            <div className="master-player-fantasy__column">
                <Typography className="master-player-fantasy__column-top">Posición</Typography>
                <Typography className="master-player-fantasy__column-bottom">
                    {getFantasyPosition()}
                </Typography>
            </div>

            {preferences.fantasy === FANTASY.BIWENGER && (
                <Divider
                    style={{
                        backgroundColor: '#E0E0E0',
                        marginTop: '12px',
                        width: '100%',
                        marginBottom: '12px',
                    }}
                />
            )}

            {preferences.fantasy === FANTASY.BIWENGER && (
                <div className="master-player-fantasy__column" style={{ width: '33%' }}>
                    <Typography className="master-player-fantasy__column-top">Subida de precio</Typography>
                    <Typography className="master-player-fantasy__column-bottom">
                        {toPriceInEuros(biwengerSelected?.priceIncrement) ?? '-'}
                    </Typography>
                </div>
            )}

            {preferences.fantasy === FANTASY.BIWENGER && (
                <div className="master-player-fantasy__column" style={{ width: '33%' }}>
                    <Typography className="master-player-fantasy__column-top">Última temporada</Typography>
                    <Typography className="master-player-fantasy__column-bottom">
                        {biwengerSelected?.pointsLastSeason
                            ? biwengerSelected?.pointsLastSeason + ' puntos'
                            : '-'}
                    </Typography>
                </div>
            )}

            {preferences.fantasy === FANTASY.BIWENGER && (
                <div className="master-player-fantasy__column" style={{ width: '33%' }}>
                    <Typography className="master-player-fantasy__column-top">Partidos</Typography>
                    <Box className="master-player-fantasy__column-bottom">
                        {Number(biwengerSelected?.playedHome) + Number(biwengerSelected?.playedAway)}
                        <HomeIcon style={{ fontSize: '14px' }} />
                        <span>{biwengerSelected?.playedHome}</span>
                        <FlightTakeoffIcon style={{ fontSize: '14px', marginLeft: '4px' }} />
                        <span>{biwengerSelected?.playedAway}</span>
                    </Box>
                </div>
            )}

            {preferences.fantasy === FANTASY.BIWENGER && (
                <div className="master-player-fantasy__column" style={{ width: '100%' }}>
                    <Typography className="master-player-fantasy__column-top">Racha actual</Typography>
                    <Box className="master-player-fantasy__column-bottom">
                        <BiwengerFitness fitness={biwengerSelected?.fitness} />
                    </Box>
                </div>
            )}
        </div>
    );
};

export default MasterPlayerFantasy;
