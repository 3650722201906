export const LEAGUES = {
    LA_LIGA: 140,
    PREMIER_LEAGUE: 39,
    SERIE_A: 135,
    PRIMEIRA_LIGA: 94,
    BUNDESLIGA: 78,
    LIGUE_1: 61,
    SEGUNDA_DIVISION: 141,
    COPA_DEL_REY: 143,
    CHAMPIONS_LEAGUE: 2,
    EUROPA_LEAGUE: 3,
    EUROPE_SUPER_CUP: 531,
    SPAIN_SUPER_CUP: 556,
    FIFA_WORLD_CLUB: 15,
    NATIONS_LEAGUE: 5,
    UEFA_EUROPA_CONFERENCE_LEAGUE: 848,
    FIFA_FRIENDLIES: 10,
    EURO_CLASIFICACION: 960,
    EUROCOPA: 4,
    COPA_AMERICA: 9,
    AMISTOSOS_CLUBES: 667,
};

export const LEAGUES_NAMES = {
    LA_LIGA: 'La Liga',
    COPA_DEL_REY: 'Copa del Rey',
    CHAMPIONS_LEAGUE: 'Champions League',
    EUROPE_SUPER_CUP: 'Supercopa de Europa',
    SPAIN_SUPER_CUP: 'Supercopa de España',
    FIFA_WORLD_CLUB: 'Mundial de Clubes',
    UEFA_EUROPA_CONFERENCE_LEAGUE: 'Conference League',
    EUROPA_LEAGUE: 'Europa League',
    FIFA_FRIENDLIES: 'FIFA - Amistosos',
    EURO_CLASIFICACION: 'Eurocopa - Clasificación',
    EUROCOPA: 'Eurocopa',
    PREMIER_LEAGUE: 'Premier League',
    COPA_AMERICA: 'Copa América',
    AMISTOSOS_CLUBES: 'Amistosos de Clubes',
    SEGUNDA_DIVISION: 'Segunda división',
};

export const LEAGUES_SEO_NAME = {
    LA_LIGA: 'la-liga',
    COPA_DEL_REY: 'copa-del-rey',
    CHAMPIONS_LEAGUE: 'champions-league',
    EUROPE_SUPER_CUP: 'supercopa-de-europa',
    SPAIN_SUPER_CUP: 'supercopa-de-españa',
    FIFA_WORLD_CLUB: 'mundial-de-clubes',
    UEFA_EUROPA_CONFERENCE_LEAGUE: 'conference-league',
    EUROPA_LEAGUE: 'europa-league',
    FIFA_FRIENDLIES: 'fifa-amistosos',
    EURO_CLASIFICACION: 'eurocopa-clasificacion',
    AMISTOSOS_CLUBES: 'amistosos-de-clubes',
    PREMIER_LEAGUE: 'premier-league',
    SEGUNDA_DIVISION: 'la-liga-segunda-division',
};
