const DudaLeveStatusIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            width={iconWidth ?? 16}
            height={iconHeight ?? 16}
            viewBox="0 0 256 256"
            xmlSpace="preserve"
        >
            <defs></defs>
            <g
                style={{
                    stroke: 'none',
                    strokeWidth: 0,
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: 10,
                    fill: 'none',
                    fillRule: 'nonzero',
                    opacity: 1,
                }}
                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
            >
                <circle
                    cx="45"
                    cy="45"
                    r="45"
                    style={{
                        stroke: 'none',
                        strokeWidth: 1,
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 10,
                        fill: '#059669',
                        fillRule: 'nonzero',
                        opacity: 1,
                    }}
                    transform="matrix(1 0 0 1 0 0)"
                />
                <path
                    d="M 42.069 49.419 c -1.657 0 -3 -1.343 -3 -3 c 0 -1.657 1.343 -3 3 -3 c 5.512 0 9.997 -4.485 9.997 -9.997 s -4.484 -9.997 -9.997 -9.997 s -9.997 4.485 -9.997 9.997 c 0 1.657 -1.343 3 -3 3 s -3 -1.343 -3 -3 c 0 -8.821 7.176 -15.997 15.997 -15.997 c 8.821 0 15.997 7.176 15.997 15.997 S 50.89 49.419 42.069 49.419 z"
                    style={{
                        stroke: 'none',
                        strokeWidth: 1,
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 10,
                        fill: '#ea580c',
                        fillRule: 'nonzero',
                        opacity: 1,
                    }}
                    transform="matrix(1 0 0 1 0 0)"
                    strokeLinecap="round"
                />
                <path
                    d="M 42.069 58.227 c -1.657 0 -3 -1.343 -3 -3 V 44.2 c 0 -1.657 1.343 -3 3 -3 s 3 1.343 3 3 v 11.026 C 45.069 56.884 43.726 58.227 42.069 58.227 z"
                    style={{
                        stroke: 'none',
                        strokeWidth: 1,
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 10,
                        fill: 'rgb(27,167,173)',
                        fillRule: 'nonzero',
                        opacity: 1,
                    }}
                    transform="matrix(1 0 0 1 0 0)"
                    strokeLinecap="round"
                />
                <path
                    d="M 44.931 57.583 c -1.657 0 -3 -1.343 -3 -3 V 43.557 c 0 -1.657 1.343 -3 3 -3 s 3 1.343 3 3 v 11.026 C 47.932 56.24 46.588 57.583 44.931 57.583 z"
                    style={{
                        stroke: 'none',
                        strokeWidth: 1,
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 10,
                        fill: 'rgb(255,255,255)',
                        fillRule: 'nonzero',
                        opacity: 1,
                    }}
                    transform="matrix(1 0 0 1 0 0)"
                    strokeLinecap="round"
                />
                <path
                    d="M 44.931 46.557 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 c 5.512 0 9.997 -4.485 9.997 -9.997 s -4.484 -9.997 -9.997 -9.997 s -9.997 4.485 -9.997 9.997 c 0 1.657 -1.343 3 -3 3 s -3 -1.343 -3 -3 c 0 -8.821 7.176 -15.997 15.997 -15.997 s 15.997 7.176 15.997 15.997 S 53.752 46.557 44.931 46.557 z"
                    style={{
                        stroke: 'none',
                        strokeWidth: 1,
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 10,
                        fill: 'rgb(255,255,255)',
                        fillRule: 'nonzero',
                        opacity: 1,
                    }}
                    transform="matrix(1 0 0 1 0 0)"
                    strokeLinecap="round"
                />
                <circle
                    cx="42.065"
                    cy="71.89500000000001"
                    r="5.665"
                    style={{
                        stroke: 'none',
                        strokeWidth: 1,
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 10,
                        fill: 'rgb(27,167,173)',
                        fillRule: 'nonzero',
                        opacity: 1,
                    }}
                    transform="matrix(1 0 0 1 0 0)"
                />
                <circle
                    cx="44.935"
                    cy="69.035"
                    r="5.665"
                    style={{
                        stroke: 'none',
                        strokeWidth: 1,
                        strokeDasharray: 'none',
                        strokeLinecap: 'butt',
                        strokeLinejoin: 'miter',
                        strokeMiterlimit: 10,
                        fill: 'rgb(255,255,255)',
                        fillRule: 'nonzero',
                        opacity: 1,
                    }}
                    transform="matrix(1 0 0 1 0 0)"
                />
            </g>
        </svg>
    );
};

export default DudaLeveStatusIcon;
