import { Suspense, lazy } from 'react';

import { Box } from '@mui/material';

const ReactSelectSuspense = lazy(() => import('./react-select-suspense'));

const ReactSelect = ({
    isMulti,
    placeholder,
    defaultValue,
    value,
    options,
    id,
    text,
    width,
    isDisabled,
    isLoading,
    onChange,
    icon,
    isVisible,
    onClose,
    onFocus,
    onInputChange,
}) => {
    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: width ?? '100%',
        }),
        menu: (provided) => ({
            ...provided,
            width: width ?? '100%',
        }),
    };

    const onChangeEvent = (e) => {
        onChange(e);
    };

    return (
        <Box className="football-stats-selector">
            {/* Usamos Suspense para cargar ReactSelectSuspense */}
            <Suspense fallback={<div>Cargando selector...</div>}>
                <ReactSelectSuspense
                    onClose={onClose}
                    text={text}
                    instanceId={id}
                    styles={customStyles}
                    options={options}
                    onChange={onChangeEvent}
                    value={value}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    icon={icon}
                    isVisible={isVisible}
                    onFocus={onFocus}
                    onInputChange={onInputChange}
                />
            </Suspense>
        </Box>
    );
};

export default ReactSelect;
