import { REDUCERS } from '@/constants/reducers';

export function activities(state, action) {
    let activities = { ...state.activities, ...action.payload };
    switch (action.type) {
        case REDUCERS.UPDATE_ACTIVITIES:
            const newState = { ...state, activities };
            return newState;
        default:
            return state;
    }
}
