const LideresEstadisticasIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 512 512">
            <path
                fill="#E2A042"
                d="M382.287 464.724c-6.201-6.996-13.561-12.868-21.523-14.416c0-.05.008-.099.008-.15c0-12.345-10.007-22.353-22.353-22.353H174.136c-12.345 0-22.353 10.007-22.353 22.353c0 .05.007.099.008.15c-7.962 1.548-15.322 7.42-21.523 14.416c-10.236 11.548-2.011 29.778 13.421 29.778h225.178c15.431 0 23.656-18.23 13.42-29.778"
            />
            <path
                fill="#FFB636"
                d="M493.587 86.056c-11.911-14.232-29.387-22.395-47.946-22.395h-24.134V34.59H91.047v29.071H66.359c-18.559 0-36.034 8.162-47.945 22.395S1.546 118.928 4.817 137.197l19.321 107.935c5.342 29.843 31.224 51.504 61.542 51.504h77.056c2.194 0 4.371-.123 6.528-.348c9.869 11.219 20.411 22.255 31.474 33.319c8.042 8.042 15.26 14.671 21.947 19.899a556 556 0 0 1-6.27 27.387l-2.902 11.009c-1.044 3.631-1.945 7.205-3.046 10.663c-1.151 3.458-2.722 6.801-4.468 9.971c-1.761 3.17-3.609 6.167-5.117 8.934a74 74 0 0 0-3.749 7.551c-.44 1.124-.906 2.176-1.212 3.149c-.295.973-.502 1.866-.577 2.673c-.374 3.228.868 5.072.868 5.072c6.216 9.245 16.262 17.142 29.488 22.184c33.012 12.583 73.333 2.651 90.06-22.184c0 0 1.242-1.844.868-5.072c-.075-.807-.282-1.7-.577-2.673c-.305-.973-.772-2.025-1.212-3.149a74 74 0 0 0-3.749-7.551c-1.508-2.767-3.356-5.764-5.117-8.934c-1.746-3.17-3.318-6.513-4.468-9.971c-1.101-3.458-2.001-7.032-3.046-10.663l-2.902-11.009a557 557 0 0 1-6.192-26.997c6.828-5.287 14.207-12.045 22.451-20.289c11.05-11.05 21.579-22.072 31.437-33.276a63 63 0 0 0 6.01.305h77.057c30.317 0 56.199-21.66 61.543-51.505l19.319-107.934c3.271-18.268-1.685-36.909-13.595-51.141M85.68 254.037a19.9 19.9 0 0 1-19.61-16.412L46.748 129.691c-1.426-7.97 2.102-13.631 4.333-16.296s7.181-7.136 15.278-7.136h24.713c.639 60.152 17.88 106.563 45.375 147.778zm379.571-124.346l-19.319 107.934a19.905 19.905 0 0 1-19.611 16.413h-50.213c27.495-41.215 44.737-87.626 45.375-147.778h24.159c8.096 0 13.047 4.471 15.278 7.136s5.757 8.325 4.331 16.295"
            />
            <path
                fill="#E2A042"
                d="M133.385 491.285C146.858 504.758 199.197 510 256.277 510s115.373-10.86 124.535-20.022s-51.063-9.408-51.063-9.408z"
            />
            <ellipse cx="256" cy="34.59" fill="#FFD469" rx="165.068" ry="28.143" />
            <path
                fill="#FFD469"
                d="M366.507 191.449c-1.965 0-3.962-.353-5.906-1.099c-8.508-3.263-12.76-12.806-9.496-21.314c12.218-31.855 11.069-50.287 9.854-69.8c-.567-9.095 6.347-16.928 15.441-17.495c9.113-.564 16.928 6.348 17.494 15.442c1.593 25.56 1.723 47.95-11.979 83.67c-2.518 6.565-8.774 10.596-15.408 10.596"
            />
        </svg>
    );
};

export default LideresEstadisticasIcon;
