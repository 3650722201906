import { REDUCERS } from '@/constants/reducers';

export function ads(state, action) {
    switch (action.type) {
        case REDUCERS.UPDATE_ADS:
            const ads = { ...state.ads, ...action.payload };
            return { ...state, ads };
        default:
            return state;
    }
}
