import { REDUCERS } from '@/constants/reducers';
import { alineaciones } from '@/helpers/analisis-plantilla-fantasy-helper';

export function fantasy(state, action) {
    switch (action.type) {
        case REDUCERS.GET_FANTASY_PLAYERS:
            const formation = alineaciones.find((f) => f.value === action.payload.data.formation);
            return {
                ...state,
                fantasy: {
                    ...state.fantasy,
                    players: action.payload.data.players,
                    formation: formation,
                    teams: action.payload.data.teams,
                },
            };
        case REDUCERS.UPDATE_RANKING:
            return { ...state, ranking: action.payload.ranking };
        case REDUCERS.GET_FANTASY_SQUAD:
            const scoreFormation = alineaciones.find((f) => f.value === action.payload.squad.formation);
            return {
                ...state,
                fantasy: {
                    ...state.fantasy,
                    squad: action.payload.squad,
                    scoreFormation,
                },
            };
        case REDUCERS.UPDATE_FANTASY_FORMATION:
            const newFormation = alineaciones.find((f) => f.value === action.payload.formation.value);

            return {
                ...state,
                fantasy: {
                    ...state.fantasy,
                    formation: newFormation,
                },
            };
        default:
            return state;
    }
}
