const RdpIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={25}
            viewBox="0 0 36 36"
            style={{ marginRight: '5px' }}
        >
            <path
                fill="#292F33"
                d="M6 15h24v2H6zm15 15c0 2.208-.792 4-3 4c-2.209 0-3-1.792-3-4s.791-2 3-2c2.208 0 3-.208 3 2"
            />
            <path fill="#66757F" d="M18 31c-6.627 0-10 1.343-10 3v2h20v-2c0-1.657-3.373-3-10-3" />
            <path fill="#99AAB5" d="M18 0a9 9 0 0 0-9 9v7h18V9a9 9 0 0 0-9-9" />
            <g fill="#292F33">
                <circle cx="15.5" cy="2.5" r="1.5" />
                <circle cx="20.5" cy="2.5" r="1.5" />
                <circle cx="17.5" cy="6.5" r="1.5" />
                <circle cx="22.5" cy="6.5" r="1.5" />
                <circle cx="12.5" cy="6.5" r="1.5" />
                <circle cx="15.5" cy="10.5" r="1.5" />
                <circle cx="10.5" cy="10.5" r="1.5" />
                <circle cx="20.5" cy="10.5" r="1.5" />
                <circle cx="25.5" cy="10.5" r="1.5" />
                <circle cx="17.5" cy="14.5" r="1.5" />
                <circle cx="22.5" cy="14.5" r="1.5" />
                <circle cx="12.5" cy="14.5" r="1.5" />
            </g>
            <path fill="#66757F" d="M9 15.062V17c0 4.971 4.029 9 9 9s9-4.029 9-9v-1.938z" />
            <path
                fill="#66757F"
                d="M30 14a1 1 0 0 0-1 1v2c0 6.074-4.925 11-11 11S7 23.074 7 17v-2a1 1 0 0 0-2 0v2c0 7.18 5.82 13 13 13s13-5.82 13-13v-2a1 1 0 0 0-1-1"
            />
        </svg>
    );
};

export default RdpIcon;
