import { useContext } from 'react';

import { REDUCERS } from '@/constants/reducers';
import { Context } from '@/context';
import { fantasyOptions } from '@/helpers/biwenger-helper';

import SelectMaterial from '../shared/custom/select-material';

const FantasySelectorComponent = () => {
    const { state, dispatch } = useContext(Context);

    const onChangeSearchFantasy = (newValue) => {
        const fantasyMode = fantasyOptions.find((x) => x.value === newValue);

        dispatch({
            type: REDUCERS.UPDATE_PREFERENCES,
            payload: {
                fantasy: fantasyMode.fantasy,
                mode: fantasyMode.mode,
            },
        });
    };

    return (
        <SelectMaterial
            id="fantasy-select"
            options={fantasyOptions}
            onChange={(e) => onChangeSearchFantasy(e.target.value)}
            value={
                fantasyOptions.find(
                    (x) => x.fantasy === state.preferences.fantasy && x.mode === state.preferences.mode
                )?.value ?? 'Fantasy'
            }
            text="Sistema de puntuación"
        />
    );
};

export default FantasySelectorComponent;
