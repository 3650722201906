import React from 'react';

const InProgressComponent = () => {
    return (
        <svg height="20" width="20" className="blinking">
            <circle cx="10" cy="10" r="5" fill="red" />
        </svg>
    );
};

export default InProgressComponent;
