import CustomImageComponent from '../custom/custom-image';

const PredictingManagerIcon = ({ iconWidth = 25, iconHeight = 25, size = 150 }) => {
    return (
        <CustomImageComponent
            src={`${process.env.NEXT_PUBLIC_IMAGES}/logos/predictingmanagers.png?width=${
                size ?? iconWidth
            }&height=${size ?? iconHeight}`}
            alt="PM"
            width={iconWidth}
            height={iconHeight}
        />
    );
};

export default PredictingManagerIcon;
