const PublicTierlistIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconHeight} viewBox="0 0 24 24">
            <g fill="none">
                <path
                    fill="#00EBE5"
                    d="m17.312 19.347l4.1-2.053L6.706 9.953L2.6 12l14.706 7.347zM12 22l4.118-2.053l-9.412-4.706l-4.089 2.053zm9.4-10l-4.106 2.047L13.2 12l4.1-2.047z"
                />
                <path
                    fill="#37EB77"
                    d="M7.888 4.059L12.006 2l4.112 2.059L12 6.106L7.888 4.053zm-1.2.594l-4.1 2.053l9.412 4.7l4.1-2.047l-9.412-4.7zm10.606 4.112l4.112-2.06l-4.1-2.04l-4.118 2.053z"
                />
            </g>
        </svg>
    );
};

export default PublicTierlistIcon;
