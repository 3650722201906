const PlantillasIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 30}
            height={iconHeight ?? 30}
            viewBox="0 0 32 32"
        >
            <path
                fill="#ffffff"
                d="M16 5a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0a4 4 0 0 1-8 0Zm13.5-1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3ZM22 7.5a3.5 3.5 0 1 1 7 0a3.5 3.5 0 0 1-7 0Zm-17 0a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0ZM6.5 4a3.5 3.5 0 1 0 0 7a3.5 3.5 0 0 0 0-7Zm2.151 20.505A3 3 0 0 1 4 22v-6.5a.5.5 0 0 1 .5-.5h4.031a3.981 3.981 0 0 1 .846-2H4.5A2.5 2.5 0 0 0 2 15.5V22a5 5 0 0 0 7.327 4.427a7.446 7.446 0 0 1-.676-1.922Zm14.022 1.922A5 5 0 0 0 30 22v-6.5a2.5 2.5 0 0 0-2.5-2.5h-4.877a3.99 3.99 0 0 1 .846 2H27.5a.5.5 0 0 1 .5.5V22a3 3 0 0 1-4.651 2.505a7.447 7.447 0 0 1-.676 1.922ZM12.5 13a2.5 2.5 0 0 0-2.5 2.5V23a6 6 0 0 0 12 0v-7.5a2.5 2.5 0 0 0-2.5-2.5h-7Zm-.5 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5V23a4 4 0 0 1-8 0v-7.5Z"
            />
        </svg>
    );
};

export default PlantillasIcon;
