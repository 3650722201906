const ApercibidosStatusIcon = ({ iconWidth, iconHeight, color, hide }) => {
    if (hide === true) return null;
    return (
        <div
            style={{
                width: iconWidth ?? 20,
                height: iconHeight ?? 20,
                background: color ?? 'rgb(255 243 106)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
                border: '1px solid rgba(0,0,0,0.2)',
                position: 'relative',
                zIndex: 1,
            }}
            className="apercibidos-status-icon"
        >
            <p style={{ margin: 0, padding: 0, fontSize: '12px', color: '#000', fontWeight: 600 }}>4</p>
        </div>
    );
};

export default ApercibidosStatusIcon;
