const { default: CustomImageComponent } = require('../custom/custom-image');

const BiwengerIcon = ({ width, height, style }) => {
    return (
        <CustomImageComponent
            src={`${process.env.NEXT_PUBLIC_IMAGES}/logos/biwenger-logo.png?width=50`}
            width={width ?? 30}
            height={height ?? width ?? 30}
            alt={'biwenger'}
            style={style}
        />
    );
};

export default BiwengerIcon;
