import { useContext, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Typography, Card, CardContent, IconButton, Collapse } from '@mui/material';
import { useRouter } from 'next/router';


import { REDUCERS } from '@/constants/reducers';
import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';
import { translate } from '@/data/fixture-transalations';
import { getDate_dd_MM } from '@/helpers/datetime-helper';
import { getCssClass } from '@/helpers/fixtures-helper';
import { getTeamImageById } from '@/helpers/images-helper';
import { getCompetitionImage } from '@/helpers/leagues-helper';

import CustomImageComponent from '../shared/custom/custom-image';

const NextRivalsTablesComponent = ({ player }) => {
    const [collapsedLastFixtures, setCollapsedLastFixtures] = useState(false);
    const [collapsedUpcomingFixtures, setCollapsedUpcomingFixtures] = useState(false);
    const router = useRouter();
    const { dispatch } = useContext(Context);

    const handleCollapse = (type) => {
        if (type === 'last') {
            setCollapsedLastFixtures(!collapsedLastFixtures);
        } else {
            setCollapsedUpcomingFixtures(!collapsedUpcomingFixtures);
        }
    };

    const handleClickFixture = (fixtureId) => {
        dispatch({ type: REDUCERS.HIDE_PLAYER_PROFILE });
        router.push(`${Rutas.PARTIDO}/${fixtureId}/alineaciones-probables`);
    };

    const renderFixture = (fixture) => {
        const rival = fixture?.home?.id === player?.team?.id ? fixture?.away : fixture?.home;
        const icon = fixture?.home?.id === player?.team?.id ? <HomeIcon /> : <FlightTakeoffIcon />;
        const cssClass = getCssClass(fixture);

        return (
            <Box key={fixture.id} className="fixture-box" onClick={() => handleClickFixture(fixture.id)}>
                <Box className="fixture-box__header">
                    <Box className="fixture-box__league">
                        <CustomImageComponent
                            src={getCompetitionImage(fixture?.leagueId)}
                            width={15}
                            height={15}
                            alt="Competición"
                        />
                    </Box>
                    <Typography className="fixture-box__round">{translate(fixture.round, true)}</Typography>
                </Box>
                <Box className="fixture-box__body">
                    <CustomImageComponent src={getTeamImageById(rival?.id)} width={40} height={40} alt={rival.name} />
                    <Box className="fixture-box__result">
                        <Typography className={`${cssClass} fixture-box__result-score`}>
                            {fixture?.home?.goals} - {fixture?.away?.goals}
                        </Typography>
                        <Box className="fixture-box__icon">
                            {icon}
                            <Typography className="fixture-box__date">
                                {getDate_dd_MM(fixture?.date)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    const renderTable = (title, fixtures, isCollapsed, collapseKey) => {
        return (
            <Card className="fixtures-table">
                <CardContent className="fixtures-table__card-content" >
                    <Box className="fixtures-table__header">
                        <Typography variant="h6" className="fixtures-table__title">
                            {title.toUpperCase()}
                        </Typography>
                        <IconButton onClick={() => handleCollapse(collapseKey)}>
                            {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </IconButton>
                    </Box>
                    <Collapse in={!isCollapsed}>
                        <Box className="fixtures-table__content">
                            {fixtures.slice(0, 5).map((fixture) => renderFixture(fixture))}
                        </Box>
                    </Collapse>
                </CardContent>
            </Card>
        );
    };

    return (
        <Box className="rivals-tables">
            <Box className="rivals-tables__column">
                {renderTable(
                    'Últimos rivales',
                    player?.fixtures?.lastFixtures,
                    collapsedLastFixtures,
                    'last'
                )}
            </Box>
            <Box className="rivals-tables__column">
                {renderTable(
                    'Próximos rivales',
                    player?.fixtures?.upcomingFixtures,
                    collapsedUpcomingFixtures,
                    'upcoming'
                )}
            </Box>
        </Box>
    );
};

export default NextRivalsTablesComponent;
