import { useRouter } from 'next/router';

import { Rutas } from '@/constants/rutas';

import CustomImageComponent from './custom/custom-image';

const Logo = ({ styles, width, disableClick }) => {
    const router = useRouter();

    return (
        <CustomImageComponent
            className="image-logo__image"
            src={`${process.env.NEXT_PUBLIC_IMAGES}/analiticafantasy.png?width=${width ? width * 1.5 : 105}`}
            alt="logo"
            width={width ?? 70}
            height={width ?? 70}
            priority={true}
            onClick={() => {
                if (disableClick) {
                    return;
                }
                router.push(Rutas.INICIO);
            }}
            style={styles}
        />
    );
};

export default Logo;
