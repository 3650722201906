import CustomImageComponent from '../custom/custom-image';

const AnaliticaFantasyIcon = ({ styles, width }) => {
    return (
        <CustomImageComponent
            className="image-logo__image"
            src={`${process.env.NEXT_PUBLIC_IMAGES}/logos/logo.png`}
            alt="logo"
            width={width ?? 70}
            height={width ?? 70}
            priority={true}
            style={styles}
        />
    );
};

export default AnaliticaFantasyIcon;
