const ColaboradoresIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 30}
            height={iconHeight ?? 30}
            viewBox="0 0 32 32"
        >
            <path
                fill="#ffffff"
                d="M8 9a4 4 0 1 1 4-4a4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2a2 2 0 0 0-2-2zm16 6a4 4 0 1 1 4-4a4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2a2 2 0 0 0-2-2zm2 27h-4a2 2 0 0 1-2-2v-7h2v7h4v-9h2v-6a1 1 0 0 0-1-1h-6.42L16 20l-4.58-8H5a1 1 0 0 0-1 1v6h2v9h4v-7h2v7a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-7a2 2 0 0 1-2-2v-6a3 3 0 0 1 3-3h7.58L16 16l3.42-6H27a3 3 0 0 1 3 3v6a2 2 0 0 1-2 2v7a2 2 0 0 1-2 2z"
            />
        </svg>
    );
};

export default ColaboradoresIcon;
