import { currencyFormat } from './prices-helper';

export const alineaciones = [
    {
        value: 'alineacion_3-3-4',
        label: '3-3-4',
        defensa: 3,
        medios: 3,
        delanteros: 4,
        grid: [[41, 42, 43, 44], [31, 32, 33], [21, 22, 23], [11]],
        top: [[35, 0, 0, 35], [35, 0, 35], [0, 35, 0], [0]],
        gap: [5, 25, 25, 0],
    },
    {
        value: 'alineacion_3-4-3',
        label: '3-4-3',
        defensa: 3,
        medios: 4,
        delanteros: 3,
        grid: [[41, 42, 43], [31, 32, 33, 34], [21, 22, 23], [11]],
        top: [[35, 0, 35], [0, 35, 35, 0], [0, 35, 0], [0]],
        gap: [15, 10, 45, 0],
    },
    {
        value: 'alineacion_3-5-2',
        label: '3-5-2',
        defensa: 3,
        medios: 5,
        delanteros: 2,
        grid: [[41, 42], [31, 32, 33, 34, 35], [21, 22, 23], [11]],
        top: [[0, 0], [35, 0, -35, 0, 35], [0, 35, 0], [0]],
        gap: [50, 5, 35, 0],
    },
    {
        value: 'alineacion_3-6-1',
        label: '3-6-1',
        defensa: 3,
        medios: 6,
        delanteros: 1,
        grid: [[41], [31, 32, 33, 34, 35, 36], [21, 22, 23], [11]],
        top: [[-10], [50, 0, -50, -50, 0, 50], [10, 10, 10], [0]],
        gap: [0, 0, 35, 0],
    },
    {
        value: 'alineacion_4-2-4',
        label: '4-2-4',
        defensa: 4,
        medios: 2,
        delanteros: 4,
        grid: [[41, 42, 43, 44], [31, 32], [21, 22, 23, 24], [11]],
        top: [[35, 0, 0, 35], [0, 0], [-25, 15, 15, -25], [0]],
        gap: [10, 25, 15, 0],
    },
    {
        value: 'alineacion_4-3-3',
        label: '4-3-3 (ofensivo)',
        defensa: 4,
        medios: 3,
        delanteros: 3,
        grid: [[41, 42, 43], [31, 32, 33], [21, 22, 23, 24], [11]],
        top: [[15, -15, 15], [0, -35, 0], [-25, 15, 15, -25], [0]],
        gap: [25, 25, 25, 0],
    },
    {
        value: 'alineacion_4-3-3-2',
        label: '4-3-3 (defensivo)',
        defensa: 4,
        medios: 3,
        delanteros: 3,
        grid: [[41, 42, 43], [31, 32, 33], [21, 22, 23, 24], [11]],
        top: [[15, -15, 15], [-15, 35, -15], [-25, 15, 15, -25], [0]],
        gap: [25, 25, 25, 0],
    },
    {
        value: 'alineacion_4-4-2',
        label: '4-4-2',
        defensa: 4,
        medios: 4,
        delanteros: 2,
        grid: [[41, 42], [31, 32, 33, 34], [21, 22, 23, 24], [11]],
        top: [[0, 0], [-15, 25, 25, -15], [-25, 15, 15, -25], [0]],
        gap: [25, 15, 25, 0],
    },
    {
        value: 'alineacion_4-5-1',
        label: '4-5-1',
        defensa: 4,
        medios: 5,
        delanteros: 1,
        grid: [[41], [31, 32, 33, 34, 35], [21, 22, 23, 24], [11]],
        top: [[0], [-30, 0, 30, 0, -30], [-25, 15, 15, -25], [0]],
        gap: [0, 0, 15, 0],
    },
    {
        value: 'alineacion_4-6-0',
        label: '4-6-0',
        defensa: 4,
        medios: 6,
        delanteros: 0,
        grid: [[], [31, 32, 33, 34, 35, 36], [21, 22, 23, 24], [11]],
        top: [[], [-45, 25, 80, 80, 25, -45], [15, 55, 55, 15], [0]],
        gap: [0, 0, 15, 0],
    },
    {
        value: 'alineacion_5-2-3',
        label: '5-2-3',
        defensa: 5,
        medios: 2,
        delanteros: 3,
        grid: [[41, 42, 43], [31, 32], [21, 22, 23, 24, 25], [11]],
        top: [[35, -15, 35], [10, 10], [-45, 0, 45, 0, -45], [20]],
        gap: [15, 35, 0, 0],
    },
    {
        value: 'alineacion_5-3-2',
        label: '5-3-2',
        defensa: 5,
        medios: 3,
        delanteros: 2,
        grid: [[41, 42], [31, 32, 33], [21, 22, 23, 24, 25], [11]],
        top: [[0, 0], [-35, 15, -35], [-35, 0, 45, 0, -35], [0]],
        gap: [35, 25, 10, 0],
    },
    {
        value: 'alineacion_5-4-1',
        label: '5-4-1',
        defensa: 5,
        medios: 4,
        delanteros: 1,
        grid: [[41], [31, 32, 33, 34], [21, 22, 23, 24, 25], [11]],
        top: [[0], [-35, 10, 10, -35], [-35, 0, 35, 0, -35], [0]],
        gap: [0, 5, 10, 0],
    },
    {
        value: 'alineacion_3-2-4-1',
        label: '3-2-4-1',
        defensa: 3,
        medios: 6, // 2 + 4 mediocampistas en dos bloques
        delanteros: 1,
        grid: [
            [41], // Delantero
            [33, 34, 35, 36], // 4 mediocampistas ofensivos
            [31, 32], // 2 mediocampistas
            [21, 22, 23], // Defensas
            [11], // Portero
        ],
        top: [[-35], [0, -35, -35, 0], [-35, -35], [0, -35, 0], [0]],
        gap: [0, 20, 30, 30, 0],
    },
    {
        value: 'alineacion_4-2-3-1',
        label: '4-2-3-1',
        defensa: 4,
        medios: 5, // 2 + 4 mediocampistas en dos bloques
        delanteros: 1,
        grid: [
            [41], // Delantero
            [33, 34, 35], // 3 mediocampistas ofensivos
            [31, 32], // 2 mediocampistas
            [21, 22, 23, 24], // Defensas
            [11], // Portero
        ],
        top: [[-25], [0, -25, 0], [-25, -25], [-50, 0, 0, -50], [-40]],
        gap: [0, 20, 30, 20, 0],
    },
];

export const bench = [
    [71, 72, 73, 74, 75],
    [81, 82, 83, 84, 85],
    [91, 92, 93, 94, 95],
];

export const getTotalAlineado = (porteroX, defensasX, mediosX, delanterosX, alineacion) => {
    let totalPrice = Number(0);
    let totalPoints = Number(0);
    let totalAverage = Number(0);

    let portero = porteroX;
    let defensas = defensasX.filter((def, index) => index < alineacion.defensa);
    let medios = mediosX.filter((def, index) => index < alineacion.medios);
    let delanteros = delanterosX.filter((def, index) => index < alineacion.delanteros);

    let teamTotalPlayers =
        defensas.length + medios.length + delanteros.length + (portero.playerId > 0 ? 1 : 0);

    if (portero.playerId > 0) {
        totalPrice += Number(portero.marketValue);
        totalPoints += Number(portero.points);
        totalAverage += Number(portero.averagePoints);
    }

    if (defensas.length > 0) {
        totalPrice += Number(defensas.map((def) => def.marketValue).reduce((a, b) => Number(a) + Number(b)));
        totalPoints += Number(defensas.map((def) => def.points).reduce((a, b) => Number(a) + Number(b)));
        totalAverage += Number(
            defensas.map((def) => def.averagePoints).reduce((a, b) => Number(a) + Number(b))
        );
    }

    if (medios.length > 0) {
        totalPrice += Number(medios.map((def) => def.marketValue).reduce((a, b) => Number(a) + Number(b)));
        totalPoints += Number(medios.map((def) => def.points).reduce((a, b) => Number(a) + Number(b)));
        totalAverage += Number(
            medios.map((def) => def.averagePoints).reduce((a, b) => Number(a) + Number(b))
        );
    }

    if (delanteros.length > 0) {
        totalPrice += Number(
            delanteros.map((def) => def.marketValue).reduce((a, b) => Number(a) + Number(b))
        );
        totalPoints += Number(delanteros.map((def) => def.points).reduce((a, b) => Number(a) + Number(b)));
        totalAverage += Number(
            delanteros.map((def) => def.averagePoints).reduce((a, b) => Number(a) + Number(b))
        );
    }

    let total = {
        nickname: 'Total',
        marketValueToDisplay: currencyFormat(totalPrice),
        points: totalPoints,
        averagePoints:
            teamTotalPlayers > 0
                ? Math.round((totalAverage / teamTotalPlayers + Number.EPSILON) * 100) / 100
                : 0,
    };

    return total;
};

export const getGap = (g) => {
    switch (g.length) {
        case 2:
            return '60px';
        case 3:
            return '35px';
        case 4:
            return '15px';
        case 5:
            return '15px';
        case 6:
            return '10px';
        case 1:
        default:
            return '0px';
    }
};

export const getTop = (formation, i, j) => {
    const top = formation.top?.[i]?.[j];

    return top;
};
