export const getHoursAmPm = (hours) => {
    if (hours < 10 && hours >= 0) {
        return '0' + hours;
    }
    var amPmHours = hours <= 12 ? hours : hours - 12;
    return amPmHours < 10 ? '0' + amPmHours : amPmHours;
};

// DEFAULT METHOD TO DISPLAY DATE
export const getTimeShortWithMinutes = (date) => {
    if (!date) return '';
    let days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
    const dateObject = new Date(date);
    // dateObject.setMinutes(dateObject.getMinutes() + dateObject.getTimezoneOffset() * -1);

    const dayNumber = dateObject.getDay();
    const dayName = days[dayNumber];
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    return `${dayName} ${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month} ${getHoursAmPm(
        hours
    )}:${minutes < 10 ? '0' + minutes : minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
};

export const getTimeShortWithYear = (date) => {
    if (!date) return '';
    let days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
    const dateObject = new Date(date);
    // dateObject.setMinutes(dateObject.getMinutes() + dateObject.getTimezoneOffset() * -1);

    const dayNumber = dateObject.getDay();
    const dayName = days[dayNumber];
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    return `${dayName} ${day < 10 ? '0' + day : day}/${
        month < 10 ? '0' + month : month
    }/${year} ${getHoursAmPm(hours)}:${minutes < 10 ? '0' + minutes : minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
};

export const getDate_dd_MM = (date) => {
    const d = new Date(date);
    const month = d.getMonth() + 1;
    const day = d.getDate();
    return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}`;
};

export const getDateMMddHHmm = (dateCSharpFormat) => {
    // Verificar si el formato de la fecha es válido antes de intentar convertirla
    if (!dateCSharpFormat) {
        return 'Fecha inválida'; // Valor predeterminado para fechas inválidas o nulas
    }

    const parsedDate = Date.parse(dateCSharpFormat);

    // Si la fecha no puede ser parseada, retornar una cadena predeterminada
    if (isNaN(parsedDate)) {
        return 'Fecha inválida'; // Valor predeterminado para fechas no válidas
    }

    const date = new Date(parsedDate);

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${day}/${month} ${hours}:${minutes}`;
};

export const getDateMMddYYYYHHmm = (dateCSharpFormat) => {
    const date = new Date(Date.parse(dateCSharpFormat));

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${day}/${month}/${date.getFullYear()} ${hours}:${minutes}`;
};

export const convertUTCDateToLocalDate = (utcDate) => {
    if (!utcDate) return '';
    const localDate = new Date(utcDate);

    const options = {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };

    // 'es' locale is used for Spanish
    return new Intl.DateTimeFormat('es', options).format(localDate);
};

export const convertUTCDateToLocalDateShort = (utcDate) => {
    if (!utcDate) return '';
    const localDate = new Date(utcDate);

    const options = {
        day: '2-digit', // Día en formato dd
        month: '2-digit', // Mes en formato MM
        hour: '2-digit',
        minute: '2-digit',
    };

    return new Intl.DateTimeFormat('es', options).format(localDate);
};

export const convertToShortDate = (date, divider) => {
    // Crear un objeto Date a partir de la fecha original
    const dateObj = new Date(date);

    // Obtener el mes y el día del objeto Date
    const mes = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Sumamos 1 porque los meses en JavaScript son base 0
    const dia = dateObj.getDate().toString().padStart(2, '0');

    // Crear la cadena en el formato deseado "10-12"
    const fechaFormateada = `${mes}${divider ?? '-'}${dia}`;

    return fechaFormateada;
};

export const getStartAndEndDatesForMonthUTC = (currentDateSelected) => {
    const currentDate = new Date(currentDateSelected);
    const currentYear = currentDate.getUTCFullYear();
    const monthNumber = currentDate.getUTCMonth() + 1; // getUTCMonth() devuelve un rango de 0-11

    // Calcular startDate para el mes dado en UTC
    const startDate = new Date(Date.UTC(currentYear, monthNumber - 1, 1));

    // Calcular endDate para el mes siguiente en UTC
    const nextMonth = (monthNumber % 12) + 1;
    const endYear = nextMonth === 1 ? currentYear + 1 : currentYear;
    const endDate = new Date(Date.UTC(endYear, nextMonth - 1, 1));

    return {
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
    };
};

export const formatDateToDDMMYYAsString = (fechaISO8601) => {
    const meses = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
    ];

    const partes = fechaISO8601.split('T')[0].split('-');
    const año = partes[0];
    const mes = meses[parseInt(partes[1], 10) - 1];
    const dia = partes[2];

    const fechaFormateada = `${dia} de ${mes} de ${año}`;

    return fechaFormateada;
};

export const formatDateToDDMMYYYY = (fecha) => {
    if (!fecha) return '';
    const fechaObj = new Date(fecha);
    const dia = String(fechaObj.getDate()).padStart(2, '0');
    const mes = String(fechaObj.getMonth() + 1).padStart(2, '0');
    const año = fechaObj.getFullYear();

    return `${dia}/${mes}/${año}`;
};

export const formatSpanishDateTime = (date) => {
    const options = {
        day: '2-digit',
        month: '2-digit',
    };

    const formattedDate = new Date(date).toLocaleDateString('es-ES', options).replace(/\b(\d{1})\b/g, '0$1');
    const formattedTime = new Date(date).toLocaleTimeString('es-ES');

    return `${formattedDate} ${formattedTime}`;
};

export const esFechaMayorOIgualActual = (fecha) => {
    const fechaActual = new Date();
    const fechaProporcionada = new Date(fecha);

    return fechaActual >= fechaProporcionada;
};

export const getAgeFromDate = (date) => {
    if (!date) return '';
    const birthDate = new Date(date);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    return m < 0 || (m === 0 && today.getDate() < birthDate.getDate()) ? age - 1 : age;
};

export const isBirthdayToday = (fechaNacimientoISO) => {
    if (!fechaNacimientoISO) return false;

    // Convertir la cadena ISO 8601 a un objeto Date
    const fechaNacimiento = new Date(fechaNacimientoISO);

    // Verificación de tipo
    if (!(fechaNacimiento instanceof Date && !isNaN(fechaNacimiento))) {
        throw new TypeError('fechaNacimiento debe ser una cadena de fecha ISO 8601 válida');
    }

    // Obtiene la fecha actual
    const hoy = new Date();

    // Extrae el día, el mes y el año de la fecha de nacimiento
    const diaNacimiento = fechaNacimiento.getDate();
    const mesNacimiento = fechaNacimiento.getMonth();

    // Extrae el día, el mes y el año de hoy
    const diaHoy = hoy.getDate();
    const mesHoy = hoy.getMonth();

    // Verifica si el día, el mes y el año de hoy coinciden con los de la fecha de nacimiento
    return diaHoy === diaNacimiento && mesHoy === mesNacimiento;
};

export const getTimeAgo = (dateString) => {
    const currentDate = new Date();
    const date = new Date(dateString);
    const diff = currentDate - date;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 31);

    if (seconds < 60) {
        return `Hace ${seconds} segundos`;
    } else if (minutes < 60) {
        return `Hace ${minutes} minutos`;
    } else if (hours < 24) {
        return `Hace ${hours} horas`;
    } else if (days < 31) {
        if (days === 1) return `Hace ${days} día`;
        return `Hace ${days} días`;
    } else {
        return `Hace ${months} meses`;
    }
};
