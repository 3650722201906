const VerifiedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 32 32">
            <g fill="none">
                <path
                    fill="#00D26A"
                    d="M2 6a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v20a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4z"
                />
                <path
                    fill="#F4F4F4"
                    d="M13.242 23c-.383 0-.766-.143-1.059-.43l-5.744-5.642a1.453 1.453 0 0 1 0-2.08a1.517 1.517 0 0 1 2.118 0l4.685 4.601L23.443 9.431a1.517 1.517 0 0 1 2.118 0a1.452 1.452 0 0 1 0 2.08l-11.26 11.058a1.503 1.503 0 0 1-1.059.431"
                />
            </g>
        </svg>
    );
};

export default VerifiedIcon;
