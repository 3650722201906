import React, { lazy, Suspense } from 'react';
const AreaChartComponent = lazy(() => import('./index'));

const WrapperAreaComponent = ({ marketValues, player, shouldLoad = true }) => {
    return (
        <div>
            {shouldLoad && (
                <Suspense fallback={<div>Cargando gráfico...</div>}>
                    <AreaChartComponent marketValues={marketValues} player={player} />
                </Suspense>
            )}
        </div>
    );
}

export default WrapperAreaComponent;