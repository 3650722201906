import TwitterIcon from '@mui/icons-material/Twitter';
import Link from 'next/link';

export const getAuthorLogo = (author, width) => {
    switch (author) {
        case 'cancirer':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/cancirer.jpg${width ? `?width=${width}` : ''}`;
        case 'Don Quijote de la Cancha':
        case 'Don Quijote De La Cancha':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/donquijote.jpg${width ? `?width=${width}` : ''}`;
        case 'Consultor La Liga':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/consultor.jpg${width ? `?width=${width}` : ''}`;
        case 'huugo_21_10':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/huugo_21.jpg${width ? `?width=${width}` : ''}`;
        case 'The Special One':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/rafa.jpg${width ? `?width=${width}` : ''}`;
        case '@MundoFutFantasy':
        case 'Mundo del Fútbol':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/mundodelfutbol.jpg${
                width ? `?width=${width}` : ''
            }`;
        case 'Kromow':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/kromow.jpg${width ? `?width=${width}` : ''}`;
        case 'Abelinho':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/abelinho.png${width ? `?width=${width}` : ''}`;
        case 'Ferse':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/ferse-logo.jpg${width ? `?width=${width}` : ''}`;
        case 'TommyJB5':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/tommy.jpg${width ? `?width=${width}` : ''}`;
        case 'adriannde9':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/adriannde9.jpg${width ? `?width=${width}` : ''}`;
        default:
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/logo.png${width ? `?width=${width}` : ''}`;
    }
};

export const getAuthorSocial = (author) => {
    switch (author) {
        case 'cancirer':
            return (
                <p className="follow-author__promo">
                    <TwitterIcon style={{ color: 'rgb(29, 155, 240)' }} />
                    Para más contenido como este pueden seguirme a través de mis redes sociales{' '}
                    <Link href={`https://twitter.com/cancirer`}>@cancirer</Link>
                </p>
            );
        case 'Don Quijote de la Cancha':
        case 'Don Quijote De La Cancha':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/donquijote.jpg${width ? `?width=${width}` : ''}`;
        case 'huugo_21_10':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/huugo_21.jpg${width ? `?width=${width}` : ''}`;
        case 'The Special One':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/rafa.jpg${width ? `?width=${width}` : ''}`;
        case '@mundodelfutbo09':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/mundodelfutbol.jpg${
                width ? `?width=${width}` : ''
            }`;
        case 'Abelinho':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/abelinho.png${width ? `?width=${width}` : ''}`;
        case 'Ferse':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/ferse-logo.jpg${width ? `?width=${width}` : ''}`;
        case 'TommyJB5':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/tommy.jpg${width ? `?width=${width}` : ''}`;
        case 'adriannde9':
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/adriannde9.jpg${width ? `?width=${width}` : ''}`;
        default:
            return `${process.env.NEXT_PUBLIC_IMAGES}/logos/logo.png${width ? `?width=${width}` : ''}`;
    }
};
