const FantasyLaLigaEstadisticasIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 25}
            height={iconHeight ?? 25}
            viewBox="0 0 24 24"
        >
            <path
                fill="#00A0D0"
                d="M13.738 2.006L10.262 3.39v16.048l3.476-1.39V2zm-6.373 9.791L3.889 13.2V22l3.476-1.396zM20.11 9.468l-3.476-1.373v10.603L20.11 20.1z"
            />
        </svg>
    );
};

export default FantasyLaLigaEstadisticasIcon;
