import { useEffect, useState } from 'react';

import { Typography, useMediaQuery } from '@mui/material'; // Importar useMediaQuery
import { useRouter } from 'next/router';

import { Rutas } from '@/constants/rutas';
import { PWA_USER_COOKIE } from '@/constants/site';
import { getCookie, setCookie } from '@/helpers/cookies';

import ButtonMaterial from '../shared/custom/button-material';


const PwaBanner = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [os, setOs] = useState(null);
    const [browser, setBrowser] = useState(null);
    const [animation, setAnimation] = useState(false);
    const [prompt, setPrompt] = useState(null);
    const secondsToDisplayBanner = 90; // segundos para mostrar el banner
    const router = useRouter();

    const isSmallScreen = useMediaQuery('(max-width:576px)'); // Detecta si la pantalla es menor o igual a 576px

    const detectOS = () => {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

        if (macosPlatforms.indexOf(platform) !== -1) {
            return 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            return 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            return 'Windows';
        } else if (/Android/.test(userAgent)) {
            return 'Android';
        } else if (!os && /Linux/.test(platform)) {
            return 'Linux';
        }

        return 'unknown';
    };

    const detectBrowser = () => {
        const userAgent = navigator.userAgent;

        if (userAgent.match(/chrome|chromium|crios/i)) {
            return 'Chrome';
        } else if (userAgent.match(/firefox|fxios/i)) {
            return 'Firefox';
        } else if (userAgent.match(/safari/i)) {
            return 'Safari';
        } else if (userAgent.match(/opr\//i)) {
            return 'Opera';
        } else if (userAgent.match(/edg/i)) {
            return 'Edge';
        }

        return 'unknown';
    };

    const handleInstall = () => {
        if (os === 'iOS' || browser === 'Safari') {
            setIsVisible(false);
            router.push(Rutas.INSTALL_APP);
            return;
        }

        if (prompt) {
            prompt.prompt();

            prompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    setCookie(PWA_USER_COOKIE, 'accepted', 3000);
                }

                setPrompt(null);
                setIsVisible(false);
            });
        }
    };

    const handleCancel = () => {
        setIsVisible(false);
        setCookie(PWA_USER_COOKIE, 'denied', 30);
    };

    useEffect(() => {
        const cookie = getCookie(PWA_USER_COOKIE);
        if (cookie) return;

        const os = detectOS();
        setOs(os);

        const browser = detectBrowser();
        setBrowser(browser);

        let timer;

        const handler = (event) => {
            event.preventDefault();
            setPrompt(event);

            if (!window.matchMedia('(display-mode: standalone)').matches) {
                timer = setTimeout(() => {
                    setIsVisible(true);

                    setTimeout(() => {
                        setAnimation(true);
                    }, 1000);
                }, secondsToDisplayBanner * 1000);
            }
        };

        window.addEventListener('beforeinstallprompt', handler);

        if (os === 'iOS' || browser === 'Safari') {
            if (!window.matchMedia('(display-mode: standalone)').matches) {
                timer = setTimeout(() => {
                    setIsVisible(true);

                    setTimeout(() => {
                        setAnimation(true);
                    }, 1000);
                }, secondsToDisplayBanner * 1000);
            }
        }

        return () => {
            clearTimeout(timer);
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);

    return isVisible && isSmallScreen ? ( // Solo mostrar si es visible y es una pantalla pequeña
        <div className="pwa-banner-container">
            <div className={`pwa-banner ${animation ? 'pwa-banner__visible' : ''}`}>
                <Typography className="pwa-banner__header">Instala la app</Typography>
                <Typography className="pwa-banner__description">
                    Haz click en el botón de instalar para que disfrutes de nuetra app en tu dispositivo.
                </Typography>
                <div className="pwa-banner__actions">
                    <ButtonMaterial
                        variant={'outlined'}
                        color={'secondary'}
                        text="Cancelar"
                        onClick={handleCancel}
                        classes={'pwa-banner__button'}
                    />
                    <ButtonMaterial text="Instalar" onClick={handleInstall} classes={'pwa-banner__button'} />
                </div>
            </div>
        </div>
    ) : null;
};

export default PwaBanner;
