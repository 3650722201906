import URL_FACTORY from '@/helpers/url-helper';

export const teams = [
    { id: 533, name: 'Villarreal', slug: 'villarreal-cf', pre: 'del ' },
    { id: 532, name: 'Valencia', slug: 'valencia-cf', pre: 'del ' },
    { id: 723, name: 'Almería', slug: 'ud-almeria', pre: 'de la ' },
    { id: 536, name: 'Sevilla', slug: 'sevilla-fc', pre: 'del ' },
    { id: 548, name: 'Real Sociedad', slug: 'real-sociedad', pre: 'de la ' },
    { id: 541, name: 'Real Madrid', slug: 'real-madrid', pre: 'del ' },
    { id: 543, name: 'Real Betis', slug: 'real-betis', pre: 'del ' },
    { id: 798, name: 'Mallorca', slug: 'rcd-mallorca', pre: 'del ' },
    { id: 538, name: 'Celta de Vigo', slug: 'rc-celta', pre: 'del ' },
    { id: 728, name: 'Rayo Vallecano', slug: 'rayo-vallecano', pre: 'del ' },
    { id: 547, name: 'Girona', slug: 'girona-fc', pre: 'del ' },
    { id: 546, name: 'Getafe', slug: 'getafe-cf', pre: 'del ' },
    { id: 529, name: 'Barcelona', slug: 'fc-barcelona', pre: 'del ' },
    { id: 727, name: 'Osasuna', slug: 'c-a-osasuna', pre: 'del ' },
    { id: 724, name: 'Cádiz', slug: 'cadiz-cf', pre: 'del ' },
    { id: 530, name: 'Atlético de Madrid', slug: 'atletico-de-madrid', pre: 'del ' },
    { id: 531, name: 'Athletic Club', slug: 'athletic-club', pre: 'del ' },
    { id: 534, name: 'Las Palmas', slug: 'ud-las-palmas', pre: 'de la ' },
    { id: 715, name: 'Granada', slug: 'granada-cf', pre: 'del ' },
    { id: 542, name: 'Alavés', slug: 'd-alaves', pre: 'del ' },
];

export const getTeamImage = (id, width) => {
    const found = teams.find((x) => x.id === id);

    if (found) {
        return URL_FACTORY.GetTeamImageLogo(found.slug, width ?? 20);
    } else {
        return '';
    }
};

export const getTeam = (slug) => {
    if (slug) {
        return teams.filter((x) => x.slug === slug)[0];
    } else {
        return slug;
    }
};

export const getTeamName = (slug) => {
    if (slug) {
        return teams.find((x) => x.slug === slug)?.name;
    } else {
        return slug;
    }
};

export const getAllFantasyTeams = () => {
    const newTeams = [...teams];
    newTeams.unshift({ name: 'Todos', slug: 'all', hideImage: true });
    return newTeams;
};

export const getTeamsSelect = () => {
    const teamsSelect = teams.map((x) => {
        return { value: x.slug, label: x.name, id: x.id };
    });

    teamsSelect.unshift({ value: 'all', label: 'Todos', id: 0 });

    return teamsSelect;
};

export const getTeamSlugById = (id) => {
    if (id) {
        return teams.find((x) => x.id === id)?.slug;
    } else {
        return id;
    }
};

export const getTeamById = (id) => {
    return teams.find((x) => x.id == id);
};

export const getTeamBySlug = (slug) => {
    return teams.find((x) => x.slug === slug);
};

export const getTeamIdBySlug = (slug) => {
    return teams.find((x) => x.slug === slug)?.id;
};
