import { useContext, useEffect, useRef, useState } from 'react';

import { Context } from '@/context';
import { EVENTS_ACTIONS, EVENTS_CATEGORIES, logEventTracker } from '@/helpers/events-helper';

const executeVideooTvLogicWithRetries = (divId, attempt = 0) => {
    const retryTimes = [100, 1000, 2000, 3000, 4000]; // Tiempos de retry en ms

    if (
        typeof window.displaySlots !== 'function' ||
        typeof googletag === 'undefined' ||
        typeof googletag.display !== 'function'
    ) {
        if (attempt < retryTimes.length) {
            setTimeout(() => {
                executeVideooTvLogicWithRetries(divId, attempt + 1); // Intentar nuevamente
            }, retryTimes[attempt]);
        } else {
            console.error('Max retries reached, displaySlots or googletag.display not available');
        }
        return;
    }
    window.displaySlots([divId]);
};

const VideooRefresherDynamic = ({ id }) => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isAdLoaded, setIsAdLoaded] = useState(false); // Anuncio cargado
    const divRef = useRef(null);
    const totalIntervalRef = useRef(null);
    const totalTimeLimit = 30; // Tiempo total para refrescar (en segundos)
    const isDevelopment = process.env.NEXT_PUBLIC_ENV_VARIABLE === 'development';

    const { state } = useContext(Context);
    const { app } = state;

    useEffect(() => {
        if (app.cookiesAccepted && !isAdLoaded && !isDevelopment) {
            executeVideooTvLogicWithRetries(`af_digitalgreen_${id}`);
            setIsAdLoaded(true);
        }
    }, [app.cookiesAccepted, isAdLoaded]);

    // Función para refrescar el anuncio
    const refreshAdUnit = () => {
        if (typeof googletag !== 'undefined' && googletag?.cmd) {
            googletag.cmd.push(function () {
                const adContainerDiv = divRef.current?.querySelector('div[data-google-query-id]');
                if (adContainerDiv) {
                    const adContainerId = adContainerDiv.getAttribute('id');
                    const slots = googletag.pubads().getSlots();
                    const slot = slots.find((slot) => slot.getSlotElementId() === adContainerId);

                    if (slot) {
                        logEventTracker(EVENTS_ACTIONS.AD_SLOT_REFRESH, EVENTS_CATEGORIES.VIDEOO, id);
                        googletag.pubads().refresh([slot]);

                        // Resetear el tiempo después de refrescar
                        setElapsedTime(0);
                    } else {
                        console.error(
                            'No se encontró ningún slot correspondiente al div hijo con id:',
                            adContainerId
                        );
                    }
                }
            });
        } else {
            console.error('googletag o googletag.cmd no está definido.');
        }
    };

    // Contador para refrescar el anuncio cada 30 segundos
    useEffect(() => {
        if (!isAdLoaded) return;

        if (!totalIntervalRef.current) {
            totalIntervalRef.current = setInterval(() => {
                setElapsedTime((prevTime) => prevTime + 1);
            }, 1000); // Incrementa cada segundo
        }

        return () => {
            clearInterval(totalIntervalRef.current);
        };
    }, [isAdLoaded]);

    // Ejecutar refresh cuando el tiempo transcurrido alcanza el límite
    useEffect(() => {
        if (elapsedTime >= totalTimeLimit) {
            refreshAdUnit();
        }
    }, [elapsedTime]);

    return (
        <div className={`af-digitalgreen-parent_${id} af-digitalgreen-parent`}>
            <div className="af-digitalgreen-ad" id={`af_digitalgreen_${id}`} ref={divRef}></div>
        </div>
    );
};

export default VideooRefresherDynamic;
