const FullCalendarIcon = ({ iconWidth, iconHeight, color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 30}
            height={iconHeight ?? 30}
            viewBox="0 0 24 24"
        >
            <g fill="none">
                <path
                    stroke={color ?? '#0d6efd'}
                    strokeWidth="1.5"
                    d="M2 12c0-3.771 0-5.657 1.172-6.828C4.343 4 6.229 4 10 4h4c3.771 0 5.657 0 6.828 1.172C22 6.343 22 8.229 22 12v2c0 3.771 0 5.657-1.172 6.828C19.657 22 17.771 22 14 22h-4c-3.771 0-5.657 0-6.828-1.172C2 19.657 2 17.771 2 14v-2Z"
                />
                <path
                    stroke={color ?? '#0d6efd'}
                    strokeLinecap="round"
                    strokeWidth="1.5"
                    d="M7 4V2.5M17 4V2.5M2.5 9h19"
                    opacity=".5"
                />
                <path
                    fill={color ?? '#0d6efd'}
                    d="M18 17a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z"
                />
            </g>
        </svg>
    );
};

export default FullCalendarIcon;
