import { Fragment, useContext, useEffect, useState } from 'react';

import {
    Drawer,
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Tab,
} from '@mui/material';

import axiosApiInstance from '@/api/shared/axios-instance';
import { REDUCERS } from '@/constants/reducers';
import { currentSeason } from '@/constants/site';
import { Context } from '@/context';
import { formatDateToDDMMYYYY } from '@/helpers/datetime-helper';
import { EVENTS_ACTIONS, EVENTS_CATEGORIES, EVENTS_LABELS, logEventTracker } from '@/helpers/events-helper';
import { toPriceInEuros } from '@/helpers/prices-helper';
import URL_FACTORY from '@/helpers/url-helper';

import WrapperLineComponent from '../shared/charts/LineComponent/wrapper-line-component';

import MasterPlayerCardComponent from './master-player-card';
import MasterPlayerHeaderComponent from './master-player-header';

const FantasyHistoricModalComponent = () => {
    const [open, setOpen] = useState(false);
    const [player, setPlayer] = useState(null);
    const [filter, setFilter] = useState('30 días');
    const [viewMode, setViewMode] = useState('Tabla'); // Nuevo estado para la vista de Tabla o Gráfica
    const { state, dispatch } = useContext(Context);
    const { fantasyHistoric } = state;
    const [cache, setCache] = useState([]);
    const [anchor] = useState('right');

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (fantasyHistoric?.fantasyId > 0 || fantasyHistoric?.playerId > 0) {
            const isCached = cache?.find(
                (x) => x.fantasyId == fantasyHistoric?.fantasyId && x.season === fantasyHistoric?.season
            );

            if (isCached) {
                setOpen(true);
                setPlayer(isCached.data);
                logEventTracker(EVENTS_ACTIONS.FANTASY_HISTORIC, EVENTS_CATEGORIES.OPEN, EVENTS_LABELS.CACHE);
                return;
            }

            const url = URL_FACTORY.GetHistoricFantasy;

            const payload = {
                playerId: fantasyHistoric?.playerId,
                fantasyId: fantasyHistoric?.fantasyId,
                season: fantasyHistoric?.season,
            };

            dispatch({ type: REDUCERS.SHOW_SPINNER });

            logEventTracker(EVENTS_ACTIONS.FANTASY_HISTORIC, EVENTS_CATEGORIES.OPEN, EVENTS_LABELS.API);

            axiosApiInstance
                .post(url, payload)
                .then((response) => {
                    if (response?.data?.information) {
                        setOpen(true);
                        setPlayer(response?.data);

                        // Add response to cache
                        setCache((prevCache) => [
                            ...prevCache,
                            {
                                fantasyId: response?.data.fantasyPlayer?.information?.id,
                                playerId: response?.data.information?.id,
                                data: response?.data,
                                season: fantasyHistoric?.season,
                            },
                        ]);
                    }
                })
                .finally(() => {
                    dispatch({ type: REDUCERS.HIDE_SPINNER });
                });
        } else {
            setOpen(false);
        }
    }, [fantasyHistoric]);

    const getFilteredMarketValues = () => {
        if (!player) return [];

        let marketValues = player.marketValues;

        if (filter === '30 días' || viewMode === 'Tabla') {
            marketValues = marketValues.sort((a, b) => b.day - a.day).slice(0, 30); // Descendente para tabla
        } else if (filter === '7 días') {
            marketValues = marketValues.sort((a, b) => b.day - a.day).slice(0, 7); // Descendente para tabla
        }

        return marketValues?.sort((a, b) => a.day - b.day); // Ascendente para gráfica
    };

    // Método para renderizar la tabla
    const renderTable = () => {
        const filteredMarketValues = getFilteredMarketValues();
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontSize: '14px', textAlign: 'center' }}>Fecha</TableCell>
                        <TableCell style={{ fontSize: '14px', textAlign: 'center' }}>Valor</TableCell>
                        <TableCell style={{ fontSize: '14px', textAlign: 'center' }}>Subida</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredMarketValues
                        .sort((a, b) => b.day - a.day) // Aseguramos el orden descendente en la tabla
                        .map((value, index) => {
                            const change =
                                index < filteredMarketValues.length - 1
                                    ? value.marketValue - filteredMarketValues[index + 1].marketValue
                                    : '-';
                            return (
                                <TableRow key={value.created}>
                                    <TableCell style={{ fontSize: '14px', textAlign: 'center' }}>
                                        {formatDateToDDMMYYYY(value.created)}
                                    </TableCell>
                                    <TableCell style={{ fontSize: '14px', textAlign: 'center' }}>
                                        {toPriceInEuros(value.marketValue)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontSize: '14px',
                                            color: change > 0 ? '#1a892d' : change < 0 ? '#be1212' : '#000',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {change > 0 ? '+' : ''}
                                        {change !== '-' ? toPriceInEuros(change) : '-'}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        );
    };

    // Método para renderizar la gráfica
    const renderChart = () => {
        const filteredMarketValues = getFilteredMarketValues();

        const data = {
            labels: filteredMarketValues?.map((value) => value.created),
            datasets: [
                {
                    label: 'Market Value',
                    data: filteredMarketValues?.map((value) => value.marketValue),
                    fill: false,
                    backgroundColor: 'rgba(75,192,192,1)',
                    borderColor: 'rgba(75,192,192,1)',
                },
            ],
        };

        return <WrapperLineComponent data={data}/>;
    };

    // Renderización de tabs "Tabla" y "Gráfica"
    const renderViewModeTabs = () => (
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                value={viewMode}
                onChange={(e, newValue) => setViewMode(newValue)}
                aria-label="tabs"
                variant="fullWidth"
                centered
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab label="Tabla" value="Tabla" />
                <Tab label="Gráfica" value="Gráfica" />
            </Tabs>
        </Box>
    );

    // Renderización de las subtabs "7 días" y "30 días/Temporada"
    const renderSubTabs = () => {
        const isOldSeason = fantasyHistoric?.season < currentSeason;
        const secondTabLabel = isOldSeason ? 'Temporada' : '30 días';

        return (
            viewMode === 'Gráfica' && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '16px',
                        marginTop: '16px',
                    }}
                >
                    {['7 días', secondTabLabel].map((f) => (
                        <Button
                            key={f}
                            variant={filter === f ? 'contained' : 'outlined'}
                            color="secondary"
                            onClick={() => setFilter(f)}
                            sx={{
                                width: '45%',
                                textAlign: 'center',
                            }}
                        >
                            {f}
                        </Button>
                    ))}
                </Box>
            )
        );
    };

    const renderModal = () => {
        if (!player || !open) return null;
        return (
            <Drawer
                anchor={anchor}
                open={open}
                onClose={handleClose}
                className="master-player-modal"
                transitionDuration={300}
            >
                <div className="historic-header">
                    <MasterPlayerHeaderComponent
                        player={player}
                        handleClose={() =>
                            dispatch({
                                type: REDUCERS.HIDE_FANTASY_HISTORIC,
                            })
                        }
                    />
                    <MasterPlayerCardComponent
                        player={player}
                        hideFantasySelector={true}
                        hideNextRival={true}
                    />
                </div>
                <div style={{ padding: '16px' }}>
                    <Typography style={{ textAlign: 'center', fontSize: '16px', marginBottom: '16px' }}>
                        Valores de mercado de {player?.information?.name} 2024/2025
                    </Typography>
                    {renderViewModeTabs()}
                    {viewMode === 'Tabla' ? (
                        <Box sx={{ marginTop: '16px' }}>{renderTable()}</Box>
                    ) : (
                        <Box sx={{ marginTop: '16px' }}>{renderChart()}</Box>
                    )}
                    {renderSubTabs()}
                </div>
            </Drawer>
        );
    };

    return <Fragment>{renderModal()}</Fragment>;
};

export default FantasyHistoricModalComponent;
