const FIXTURE_STATUS = {
  TO_BE_DEFINED: "TBD",
  NOT_STARTED: "NS",
  SECOND_HALF: "2H",
  FIRST_HALF: "1H",
  HALF_TIME: "HT",
  EXTRA_TIME: "ET",
  BREAK_TIME: "BT",
  MATCH_SUSPENDED: "SUSP",
  MATCH_CANCELED: "CANC",
  MATCH_ABANDONED: "ABD",
  TECHNICAL_LOSS: "AWD",
  WALKOVER: "WO",
  MATCH_POSTPONED: "PST",
  MATCH_INTERRUPTED: "INT",
  PENALTIES: "P",
  MATCH_FINISHED: "FT",
  MATCH_FINISHED_AFTER_EXTRA_TIME: "AET",
  MATCH_FINISHED_AFTER_PENALTIES: "PEN",
  LIVE: "LIVE",
};

const LFM_MATCH_STATUS = {
  FINALIZADO: 7,
  APLAZADO: 11,
  NO_INICIADO: 1,
  SEGUNDA_PARTE: 4,
  DESCANSO: 3,
  PRIMERA_PARTE: 2,
};

const MAX_PLAYERS_IN_SQUAD = 24;

export { FIXTURE_STATUS, LFM_MATCH_STATUS, MAX_PLAYERS_IN_SQUAD };
