import { Typography } from '@mui/material';
import { useRouter } from 'next/router';

import { Rutas } from '@/constants/rutas';

import AlineacionesActivityIcon from '../home/icons/alineaciones-activity-icon';

import ActivityEventsIcon from './icons/activity-icon';
import ApercibidosStatusIcon from './icons/apercibidos-status-icon';
import LesionadosIcon from './icons/lesionados-icon';
import MercadoIcon from './icons/mercado-icon';
import NewsIcon from './icons/news-icon';
import NoticiasFlashIcon from './icons/noticias-flash-icon';
import OraculoIcon from './icons/oraculo-icon';
import PublicTierlistIcon from './icons/public-tierlist-icon';
import PujaIdealIcon from './icons/puja-idea-icon';
import SancionadosIcon from './icons/sancionados-icon';
import DudaStatusIcon from './icons/status-icons/duda-status-icon';

const SecondaryNavigationComponent = () => {
    const router = useRouter();

    const handleRoute = (ruta) => {
        router.push(ruta);
    };

    return (
        <div className="jugadores-navigation">
            <div className="jugadores-navigation__item" onClick={() => handleRoute(Rutas.NOTICIAS)}>
                <NewsIcon iconWidth={18} iconHeight={18} color="#1d6fb1" />
                <Typography className="jugadores-navigation__item-text">Noticias</Typography>
            </div>

            <div className="jugadores-navigation__item" onClick={() => handleRoute(Rutas.MERCADO)}>
                <MercadoIcon iconWidth={18} iconHeight={18} color="#1d6fb1" />
                <Typography className="jugadores-navigation__item-text">Mercado</Typography>
            </div>

            <div
                style={{ width: '70px' }}
                className="jugadores-navigation__item"
                onClick={() => handleRoute(Rutas.PUJA_FANTASY)}
            >
                <PujaIdealIcon iconWidth={18} iconHeight={18} color="#1d6fb1" />
                <Typography className="jugadores-navigation__item-text">Puja ideal</Typography>
            </div>

            <div className="jugadores-navigation__item" onClick={() => handleRoute(Rutas.ORACULO_FANTASY)}>
                <OraculoIcon />
                <Typography className="jugadores-navigation__item-text">Oráculo</Typography>
            </div>

            <div className="jugadores-navigation__item" onClick={() => handleRoute(Rutas.TIER_LIST_PUBLIC)}>
                <PublicTierlistIcon iconWidth={18} iconHeight={18} />
                <Typography className="jugadores-navigation__item-text">Tierlists</Typography>
            </div>

            <div className="jugadores-navigation__item" onClick={() => handleRoute(Rutas.ACTIVIDADES)}>
                <ActivityEventsIcon width={18} height={18} color="#1d6fb1" />
                <Typography className="jugadores-navigation__item-text">Actividades</Typography>
            </div>

            <div className="jugadores-navigation__item" onClick={() => handleRoute(Rutas.NOTICIAS_FLASH)}>
                <NoticiasFlashIcon color="#1a892d" iconHeight={18} iconWidth={18} />
                <Typography className="jugadores-navigation__item-text">Flash</Typography>
            </div>

            <div
                className="jugadores-navigation__item"
                onClick={() => handleRoute(Rutas.ALINEACIONES_PROBABLES)}
            >
                <AlineacionesActivityIcon color="#1d6fb1" height={18} width={18} />
                <Typography className="jugadores-navigation__item-text">Alineaciones</Typography>
            </div>

            <div
                className="jugadores-navigation__item"
                onClick={() => handleRoute(Rutas.JUGADORES_LESIONADOS)}
            >
                <LesionadosIcon />
                <Typography className="jugadores-navigation__item-text">Lesionados</Typography>
            </div>
            <div
                className="jugadores-navigation__item"
                onClick={() => handleRoute(Rutas.JUGADORES_SANCIONADOS)}
            >
                <SancionadosIcon iconHeight={18} iconWidth={18} />
                <Typography className="jugadores-navigation__item-text">Sancionados</Typography>
            </div>
            <div className="jugadores-navigation__item" onClick={() => handleRoute(Rutas.JUGADORES_DUDAS)}>
                <DudaStatusIcon />
                <Typography className="jugadores-navigation__item-text">Dudas</Typography>
            </div>
            <div
                className="jugadores-navigation__item"
                onClick={() => handleRoute(Rutas.JUGADORES_APERCIBIDOS)}
            >
                <ApercibidosStatusIcon iconHeight={18} iconWidth={18} />
                <Typography className="jugadores-navigation__item-text">Apercibidos</Typography>
            </div>
        </div>
    );
};

export default SecondaryNavigationComponent;
