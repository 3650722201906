const EntrenamientoGymIcon = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? 30}
            height={height ?? 30}
            viewBox="0 0 128 128"
        >
            <path
                fill="#211e1e"
                d="M53.82 50.59c-1.87-6.41-1.87-19.55-1.87-19.55l24.1-.1s0 11.91-1.4 19.57z"
            />
            <ellipse cx="64" cy="31.24" fill="#211e1e" rx="12.05" ry="11.33" />
            <g fill="#a6a9b2">
                <path d="M7.74 13.77c-.88 0-1.59.71-1.59 1.59c0 .88.71 1.59 1.59 1.59h43.49v-3.17H7.74zm112.48 0H83.37v3.17h36.85c.88 0 1.59-.71 1.59-1.59c-.01-.87-.72-1.58-1.59-1.58" />
                <path d="M104.66 4.24c0-2.14 1.73-3.87 3.87-3.87H110c2.14 0 3.87 1.73 3.87 3.87v22.25c0 2.14-1.73 3.87-3.87 3.87h-1.47c-2.14 0-3.87-1.73-3.87-3.87zm11.22 2.79c0-1.38 1.11-2.49 2.49-2.49h.95c1.38 0 2.49 1.11 2.49 2.49V23.7c0 1.38-1.11 2.49-2.49 2.49h-.95c-1.38 0-2.49-1.11-2.49-2.49zM23.3 26.48c0 2.14-1.73 3.87-3.87 3.87h-1.47c-2.14 0-3.87-1.73-3.87-3.87V4.24c0-2.14 1.73-3.87 3.87-3.87h1.47c2.14 0 3.87 1.73 3.87 3.87zm-11.22-2.79c0 1.38-1.11 2.49-2.49 2.49h-.95c-1.38 0-2.49-1.11-2.49-2.49V7.03c0-1.38 1.11-2.49 2.49-2.49h.95c1.38 0 2.49 1.11 2.49 2.49zm38.49-9.92h33.45v3.17H50.57z" />
            </g>
            <path
                fill="#566172"
                d="M19.57 27.94h-1.75c-1.54 0-2.89-.64-3.73-1.61v.16c0 2.14 1.73 3.87 3.87 3.87h1.47c2.14 0 3.87-1.73 3.87-3.87v-.16c-.84.97-2.2 1.61-3.73 1.61M8.54 24.23c-.97 0-1.82-.37-2.38-.95v.41c0 1.38 1.11 2.49 2.49 2.49h.95c1.38 0 2.49-1.11 2.49-2.49v-.41c-.56.57-1.41.95-2.38.95zm99.85 3.71h1.75c1.54 0 2.89-.64 3.73-1.61v.16c0 2.14-1.73 3.87-3.87 3.87h-1.47c-2.14 0-3.87-1.73-3.87-3.87v-.16c.83.97 2.19 1.61 3.73 1.61m11.03-3.71c.97 0 1.82-.37 2.38-.95v.41c0 1.38-1.11 2.49-2.49 2.49h-.95c-1.38 0-2.49-1.11-2.49-2.49v-.41c.56.57 1.41.95 2.38.95z"
            />
            <path fill="#4568ad" d="m49.24 127.83l-12.98-.03v-2.22a4.776 4.776 0 0 1 4.78-4.76l8.21.02z" />
            <path
                fill="#fadcbc"
                d="m45.67 99.96l-7.75-8.38s3.32-5.93 7.53-10.39c4.19-4.44 9.89-7.65 9.89-7.65l8.56 9.19s-3.33 6.13-7.57 9.77s-10.66 7.46-10.66 7.46"
            />
            <circle cx="42.75" cy="94.41" r="5.56" fill="#fadcbc" />
            <path
                fill="#651eff"
                d="M63.41 73.99a7.249 7.249 0 0 0-10.25.07c-3.92 3.11-7.12 6.08-9.79 9.54L53.8 94.52s2.45-1.75 4.36-3.44c1.11-.98 2.86-2.59 3.5-3.17c.83-.75 1.87-.72 2.33-.72l1.17-10.35a6.901 6.901 0 0 0-1.75-2.85"
            />
            <path
                fill="#fadcbc"
                d="m49.26 120.86l-6.73-.02c-3.1-12.78-5.33-18.77-5.31-25.98l11.03.49s1.3 2.46 1.6 6.21c.4 5.08-.39 11.78-.41 12.53c-.14 3.89-.18 6.77-.18 6.77"
            />
            <path fill="#e0e0e0" d="M49.42 114.09h-8.6l1.7 6.75l6.74.02z" />
            <path fill="#4568ad" d="m78.71 127.83l12.98-.03v-2.22a4.776 4.776 0 0 0-4.78-4.76l-8.21.02z" />
            <path
                fill="#fadcbc"
                d="m82.28 99.96l7.75-8.38s-3.32-5.93-7.53-10.39c-4.19-4.44-9.89-7.65-9.89-7.65l-8.56 9.19s3.33 6.13 7.57 9.77s10.66 7.46 10.66 7.46"
            />
            <circle cx="85.2" cy="94.41" r="5.56" fill="#fadcbc" />
            <path
                fill="#651eff"
                d="M64.54 73.99a7.249 7.249 0 0 1 10.25.07c3.92 3.11 7.12 6.08 9.79 9.54L74.15 94.52s-2.45-1.75-4.36-3.44c-1.11-.98-2.86-2.59-3.5-3.17c-.83-.75-1.87-.72-2.33-.72l-1.17-10.35c.35-1.06.93-2.04 1.75-2.85"
            />
            <path
                fill="#fadcbc"
                d="m78.7 120.86l6.73-.02c3.1-12.78 5.33-18.77 5.31-25.98l-11.03.49s-1.3 2.46-1.6 6.21c-.4 5.08.39 11.78.41 12.53c.14 3.89.18 6.77.18 6.77"
            />
            <path fill="#e0e0e0" d="M78.53 114.09h8.6l-1.7 6.75l-6.73.02z" />
            <path
                fill="#651eff"
                d="M59.64 45.24c-7.01 0-10.96 3.47-10.96 7.83c-.05 4.23 1.08 6.75 2.29 9.74c-.04 4.38.27 9.7 1.99 14.61h11.01V45.24z"
            />
            <path fill="#3615af" d="M51.02 66.04c.06 1.69.2 3.34.43 4.95h12.53v-4.95z" />
            <path
                fill="#651eff"
                d="M68.32 45.24c7.01 0 11.24 3.39 11.24 7.76c.05 4.23-1.37 6.82-2.58 9.82c.04 4.38-.27 9.7-1.99 14.61H63.98V45.24z"
            />
            <path fill="#3615af" d="M76.94 66.04c-.06 1.69-.2 3.34-.43 4.95H63.99v-4.95z" />
            <circle cx="40.3" cy="33.59" r="3.71" fill="#70534a" transform="rotate(-30.265 40.295 33.587)" />
            <path
                fill="#fadcbc"
                d="m35.54 19.27l-5.16 2.5c1.06 5.38 3.34 10.2 6.76 13.78l6.44-3.67c-1.72-5.66-4.17-8.82-8.04-12.61m1.61 16.28l6.44-3.67c6.21 2.96 9.35 7.54 11.97 15.84l-6.55 4.5c-5.63-4.48-9.73-9.64-11.86-16.67"
            />
            <path
                fill="#fadcbc"
                d="M32.38 12.06h.21c2.34 0 4.23 1.9 4.23 4.24v2.42c0 2.34-1.9 4.23-4.24 4.23h-.21c-2.34 0-4.23-1.9-4.23-4.24v-2.42a4.24 4.24 0 0 1 4.24-4.23"
            />
            <path
                fill="#70534a"
                d="M48.84 51.59a3.97 3.97 0 0 1 1.67-5.36c2.99-1.57 4.51.05 5.36 1.67a3.97 3.97 0 0 1-7.03 3.69"
            />
            <path
                fill="#651eff"
                d="M55.16 53.13c2.14-1.57 2.99-4.08 1.8-6.33c-1.56-2.96-2.92-4.62-5.09-7.61l-9.22 6.86c1.92 2.46 4.91 5.78 7.1 7.03c1.97 1.33 3.27 1.62 5.41.05"
            />
            <circle cx="87.7" cy="33.59" r="3.71" fill="#70534a" transform="rotate(-59.735 87.705 33.587)" />
            <path
                fill="#fadcbc"
                d="m92.46 19.27l5.16 2.5c-1.06 5.38-3.34 10.2-6.76 13.78l-6.44-3.67c1.72-5.66 4.17-8.82 8.04-12.61m-1.61 16.28l-6.44-3.67c-6.21 2.96-9.35 7.54-11.97 15.84l6.55 4.5c5.63-4.48 9.73-9.64 11.86-16.67"
            />
            <path
                fill="#fadcbc"
                d="M95.62 12.06h-.21c-2.34 0-4.23 1.9-4.23 4.24v2.42c0 2.34 1.9 4.23 4.24 4.23h.21c2.34 0 4.23-1.9 4.23-4.24v-2.42a4.24 4.24 0 0 0-4.24-4.23"
            />
            <path
                fill="#70534a"
                d="M79.16 51.59a3.97 3.97 0 0 0-1.67-5.36c-2.99-1.57-4.51.05-5.36 1.67a3.97 3.97 0 0 0 7.03 3.69"
            />
            <path
                fill="#651eff"
                d="M72.84 53.13c-2.14-1.57-2.99-4.08-1.8-6.33c1.56-2.96 2.92-4.62 5.09-7.61l9.22 6.86c-1.92 2.46-4.91 5.78-7.1 7.03c-1.97 1.33-3.27 1.62-5.41.05"
            />
            <path
                fill="#c17b47"
                d="M64 49.62c-2.36 0-4.27-1.91-4.27-4.27V41.7h8.54v3.65c0 2.36-1.91 4.27-4.27 4.27"
            />
            <path
                fill="#fadcbc"
                d="M64 22.11c-6.98 0-8.81 5.41-8.81 13.02c0 7.6 5.42 9.92 8.81 9.92c3.39 0 8.81-2.32 8.81-9.92c0-7.61-1.83-13.02-8.81-13.02"
            />
            <path
                fill="#c17b47"
                d="M60.97 35.43c0 .82-.55 1.48-1.22 1.48c-.68 0-1.22-.66-1.22-1.48s.55-1.48 1.22-1.48c.68.01 1.22.67 1.22 1.48m6.03 0c0 .82.55 1.48 1.22 1.48c.67 0 1.22-.66 1.22-1.48s-.55-1.48-1.22-1.48c-.68.01-1.22.67-1.22 1.48m-.35 4.99h-5.3c-.3 0-.43.2-.23.51c.28.43 1.32 1.25 2.88 1.25s2.6-.82 2.88-1.25c.2-.3.07-.51-.23-.51m-1.37-2.25c-.45.13-.92.19-1.28.19s-.83-.06-1.28-.19c-.19-.05-.27.13-.2.25c.14.25.72.76 1.48.76s1.34-.51 1.48-.76c.07-.12-.01-.3-.2-.25"
            />
            <path
                fill="#211e1e"
                d="M66.8 27.94c-.93.89-5.52 5.03-11.86 6.11v-8.49s2.73-5.43 9.3-5.43s9.3 5.56 9.3 5.56v8.36s-4.3-1.65-6.46-6.06a.17.17 0 0 0-.28-.05"
            />
        </svg>
    );
};

export default EntrenamientoGymIcon;
