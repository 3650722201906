const SancionadosIcon = ({ iconWidth, iconHeight }) => {
    return (
        <div
            style={{
                width: iconWidth ?? 25,
                height: iconHeight ?? 25,
                background: '#e11d48',
                borderRadius: '4px',
                border: '1px solid #fff',
            }}
        ></div>
    );
};

export default SancionadosIcon;
