const ClasificacionNavIcon = ({ width, height, color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 25} height={height ?? 25} viewBox="0 0 24 24">
            <path
                fill={color ?? '#fff'}
                d="M4 19h4.673v-8H4zm5.673 0h4.654V5H9.673zm5.654 0H20v-6h-4.673zM3 20V10h5.673V4h6.654v8H21v8z"
            />
        </svg>
    );
};

export default ClasificacionNavIcon;
