const CrearAlineacionesIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 25}
            height={iconHeight ?? 25}
            viewBox="0 0 128 128"
        >
            <path
                fill="#8B5738"
                d="M31.13 110.55c0-4.3 6.01-19.49 6.01-19.49 5.79-1.79 47.6-.27 53.97-.27 0 0 5.76 12.33 5.76 19.76S79.19 124 64.06 124s-32.93-6.02-32.93-13.45"
            />
            <ellipse cx="64.06" cy="93.5" fill="#FFB17A" rx="27.05" ry="11.75" />
            <path
                fill="#CC8552"
                d="M38.67 106.15c3.02 1.72 7.8 1.59 11.51 2.26 4.72.86 7.39 4.46 16.49 4.3 3.25-.06 10.83-.43 10.41-.42-.26-.53-1.74-1.04-4.62-1.49-2.64-.42-4.54-.38-7.6-.38-2.34 0-5.55-.7-8.79-2.48-1.48-.81-3.78-1.71-6.03-2.17-3.46-.71-5.87.04-10.7-2.25-2.31-1.09-4.18-3.35-4.62-5.98l-1.14 3.28c1.5 2.75 2.87 4.07 5.09 5.33"
            />
            <path
                fill="#A86D44"
                d="M74.51 117.42c-4.28.78-8.38-.55-12.59-1.54-5.77-1.35-16.61-1.16-16.61-1.16-.65.32 5.11 2.41 7.91 2.65 2.55.22 5.25 0 7.67.99 3.41 1.39 6.71 2.09 10.01 2.09 6.27 0 14.46-3.78 16.41-8.69 0 0-3.58 2.1-6.36 3.51-2.02 1.03-4.2 1.75-6.44 2.15"
            />
            <path
                fill="#A86D44"
                d="M35.82 111.48c.85 1.36 1.72 2.62 2.86 3.75 1.93 1.92 4.37 3.03 5.94 2.75 0 0-5.56-4.49-4.97-5.66.3-.6 11.84.35 11.84.35s-3.5-1.84-6.91-2.5c-2.21-.43-6.25.43-9.93-2.16-2.03-1.43-2.34-3.15-2.34-3.15s-.73 2.49-.88 3.44c2.13 2.23 4.11 2.73 4.39 3.18"
            />
            <path
                fill="#A86D44"
                d="M94.3 106.54c-2.02 1.81-5.21 5.47-5.21 7.36 0 0 3.58-3.47 5.99-5.06.6-.39 1.03-.89 1.5-1.42 0 0-.24-1.79-.62-3.06-.15.85-.76 1.38-1.66 2.18"
            />
            <radialGradient
                id="gradient1"
                cx="77.121"
                cy="66.694"
                r="76.184"
                gradientTransform="rotate(-3.714 -608.323 2.714)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset=".104" stopColor="#CE93D8" />
                <stop offset="1" stopColor="#AB47BC" />
            </radialGradient>
            <circle cx="64" cy="52.76" r="48.76" fill="url(#gradient1)" />
            <radialGradient id="gradient2" cx="53.349" cy="46.135" r="69.389" gradientUnits="userSpaceOnUse">
                <stop offset=".28" stopColor="#81D4FA" stopOpacity="0" />
                <stop offset=".964" stopColor="#81D4FA" stopOpacity=".9" />
            </radialGradient>
            <circle cx="64" cy="52.76" r="48.76" fill="url(#gradient2)" />
            <linearGradient
                id="gradient3"
                x1="74.69"
                x2="35.942"
                y1="10.651"
                y2="111.007"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#673AB7" />
                <stop offset=".937" stopColor="#673AB7" stopOpacity="0" />
            </linearGradient>
            <path
                fill="url(#gradient3)"
                d="M45.91 22.37c4.54.39 13.84 3.79 7.07 12.51C48.53 40.61 42.97 45.94 41 50.55c-5.69 13.33 8.57 18.77 13 20.16 3.84 1.21 11.24 3.91 13.32 7.36C75.35 91.37 50.93 95 50.93 95s17.97 6.55 32.77-3.46c5.6-3.79 10.53-12.61 5.71-20.55-2.34-3.85-8.79-7.32-10.37-8.56-6.76-5.34-10.93-10.86-7.57-17.05 1.36-2.5 4.2-3.8 6.95-4.56 7.7-2.13 17.31-1.75 19.82-11.26C100.41 21.36 84.57 3.04 60 6.02c-10.2 1.23-19.38 5.5-26.89 12.52-13.86 12.97-12.66 23.7-12.66 23.7s9.03-21.3 25.46-19.87"
                opacity=".7"
            />
            <linearGradient
                id="gradient4"
                x1="80.878"
                x2="80.878"
                y1="24.934"
                y2="108.077"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset=".235" stopColor="#1D44B3" />
                <stop offset=".884" stopColor="#2044B3" stopOpacity=".074" />
                <stop offset=".936" stopColor="#2144B3" stopOpacity="0" />
            </linearGradient>
            <path
                fill="url(#gradient4)"
                d="M99.77 24.22c-3.48-5.06-8.29-9.08-13.36-12.55-8.09-5.53-18.55-5.98-18.55-5.98 12.84 6.14 11.4 14.28 7.21 20.1-5.1 7.09-15.33 11.88-18 20.51-1.56 5.04-.6 10.34 4.31 13.56s22.58 11.77 23.19 21.89c.46 7.62-5.6 12.42-5.6 12.42 7.09-1.53 22.01-11.97 17.59-22.71-2.14-5.2-11.87-8.65-6.97-14.58 1.82-2.21 14.53-3.39 15.72-16.25.55-6.01-2.69-12.27-5.54-16.41"
                opacity=".39"
            />
            <path
                fill="#FFF"
                d="M24.71 61.66s-7.3-19.36 7.69-37.29c12.35-14.78 23.5-8.8 21.78-2.5-1.73 6.3-7.1 8.24-15.19 14.57C28.3 44.8 24.71 61.66 24.71 61.66"
                opacity=".85"
            />
            <linearGradient
                id="gradient5"
                x1="85.871"
                x2="85.871"
                y1="28.96"
                y2="52.387"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset=".261" stopColor="#FFF" />
                <stop offset="1" stopColor="#FFF" stopOpacity="0" />
            </linearGradient>
            <path
                fill="url(#gradient5)"
                d="M92.96 34.77c-1.95-.97-3.51-2.19-4.19-4.26l-2.89-9.57-2.89 9.57c-.68 2.06-2.25 3.29-4.19 4.26l-4.83 2.09 4.97 2.06c1.95.97 3.37 2.3 4.06 4.36l2.89 9.49 2.89-9.49c.68-2.06 2.11-3.39 4.06-4.36l4.97-2.06z"
            />
        </svg>
    );
};

export default CrearAlineacionesIcon;
