const LesionadosIcon = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 18}
            height={iconHeight ?? 18}
            viewBox="0 0 48 48"
        >
            <mask id="IconifyId18b950d104f7a250c2">
                <path
                    fill="#fff"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M7 18h11V7a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v11h11a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H30v11a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2V30H7a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2Z"
                />
            </mask>
            <path fill="#e11d48" d="M0 0h48v48H0z" mask="url(#IconifyId18b950d104f7a250c2)" />
        </svg>
    );
};

export default LesionadosIcon;
